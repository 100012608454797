<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-03-29 09:39:50
 * @LastEditTime: 2024/04/25
 * @Descripttion: 【教学】授课
-->
<style lang="scss" scoped>
.instruct {
  @include innerPage;
  @include pageHead(-42px);
  @include pageFoot;

  .page-inner {
    width: 100%;
    height: calc(100% - 162px);
    box-sizing: border-box;
    padding-top: 24px;

    .wrapper {
      width: calc(100% + 20px);
      height: 100%;
      box-sizing: border-box;
      padding-right: 20px;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox(space-between, flex-start);

      &-left {
        width: calc(100% - 332px);
        height: 1492px;
      }

      &-right {
        width: 304px;
        height: 1492px;
      }
    }

    .attend-class {
      width: 100%;
      height: 270px;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 40px;
      position: relative;
      @include flexBox(space-between, flex-start);

      img {
        width: 264px;
        height: 190px;
        object-fit: cover;
        border-radius: 10px;
      }

      .info-wrapper {
        width: calc(100% - 290px);

        .subject {
          height: 50px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba($color: #8e8e8e, $alpha: 0.18);
          padding-bottom: 8px;
          padding-right: 24px;
          @include flexBox(space-between);

          .bold {
            font-size: 20px;
            color: #2b2b2b;
          }
        }

        .info-detail {
          max-width: 100%;
          box-sizing: border-box;
          padding: 30px 174px 0 0;
          position: relative;
          flex-direction: column;
          @include flexBox(flex-start, flex-start);
          display: inline-flex;

          p {
            font-size: 16px;
            line-height: 32px;
            color: #2b2b2b;
          }

          .search {
            position: absolute;
            right: 0;
            top: 24px;
            height: 48px;
            line-height: 48px;

            .iconfont-color {
              display: inline-flex;
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background: rgba($color: #6340c8, $alpha: 0.09);
              align-items: center;
              justify-content: center;
              font-size: 24px;
              margin-right: 10px;
            }

            .iconfont {
              margin-left: 8px;
            }
          }
        }

        ::v-deep {
          .el-switch .el-switch__core {
            width: 75px !important;
          }

          .el-switch.text-inner .el-switch__label {
            width: 42px;
            text-align: center;
          }
        }
      }

      .start {
        position: absolute;
        bottom: 34px;
        right: 70px;
      }
    }

    .lesson-time {
      width: 100%;
      height: 270px;
      background: #f0f3fa;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 0 18px;
      color: #2b2b2b;
      line-height: 24px;
      flex-direction: column;
      @include flexBox(flex-start);

      .day {
        width: 100%;
        height: 84px;
        font-size: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba($color: #bcbcbc, $alpha: 0.34);
        @include flexBox(center);
      }

      .time {
        color: #464646;
        margin: 4px 0 22px;
      }

      h4 {
        margin-top: 42px;
      }
    }

    .students {
      width: 100%;
      height: calc(100% - 300px);
      margin-top: 30px;
      background: #f0f3fa;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 24px 18px;

      .statistics-board {
        width: 100%;
        height: 130px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba($color: #bcbcbc, $alpha: 0.34);
        flex-direction: column;
        @include flexBox(center, flex-start);

        p {
          width: calc(100% - 26px);
          height: 34px;
          color: #2e2e2e;
          @include flexBox(space-between);

          span {
            &:first-child {
              font-size: 16px;
            }
          }

          b {
            font-size: 24px;
          }
        }
      }
      .student-list {
        width: calc(100% + 8px);
        height: calc(100% - 160px);
        margin-top: 30px;
        box-sizing: border-box;
        padding: 0 38px 0 16px;
        overflow: hidden;
        overflow-y: auto;

        li {
          width: 100%;
          height: 62px;
          margin-bottom: 30px;
          @include flexBox;

          &:last-child {
            margin-bottom: 0;
          }

          .el-avatar {
            flex-shrink: 0;
          }

          .name {
            font-size: 16px;
            color: #2b2b2b;
            margin-left: 10px;
            @include ellipsisMultiline(2);
          }

          .connect-status {
            margin-left: auto;
            color: rgba($color: #7a7a7a, $alpha: 0.39);
            flex-shrink: 0;
            @include flexBox;

            i {
              display: inline-block;
              width: 10px;
              height: 10px;
              background: rgba($color: #7a7a7a, $alpha: 0.39);
              border-radius: 50%;
              margin-right: 4px;
            }

            &.connected {
              color: #2ac293;

              i {
                background: #2ac293;
              }
            }
            &.submit {
              color: #6340c8;

              i {
                background: #6340c8;
              }
            }
          }
        }
      }
    }

    &.finish {
      .wrapper {
        &-left {
          width: calc(100% - 612px);
          height: 910px;

          .box-head {
            border-radius: 10px;
            margin-top: 28px;
            box-sizing: border-box;
            padding-right: 40px;
          }
        }

        &-right {
          width: 596px;
          height: 910px;
        }
      }

      .attend-class .info-wrapper {
        .subject {
          display: block;

          .bold {
            font-size: 20px;
            color: #2b2b2b;
          }
        }

        .info-detail {
          padding: 30px 0 0;
          display: block;
        }
      }

      .plate {
        &-group {
          margin-top: 14px;
        }

        height: 250px;
        box-sizing: border-box;
        padding-top: 14px;

        h5 {
          height: 60px;
          line-height: 60px;
          color: #474747;
          box-sizing: border-box;
          padding-left: 30px;
        }

        &:not(.courseware) {
          .inner-box {
            height: calc(100% - 60px);
            padding: 0 30px 18px;
          }

          .list-wrapper {
            width: calc(100% + 20px);
            padding-right: 20px;

            li {
              height: 48px;
              border: none;
            }
          }

          .homework-title {
            height: 48px;
          }

          .homework-desc {
            width: calc(100% + 20px);
            height: calc(100% - 66px);
            padding-right: 20px;
          }
        }
      }

      .courseware {
        h5 {
          padding-left: 64px;
          color: #2b2b2b;
        }

        &-inner {
          height: calc(100% - 60px);
          padding: 0 28px;
        }

        &--info {
          box-sizing: border-box;
          padding-left: 34px;

          .info-data {
            width: 100%;

            .file-title {
              .iconfont-color {
                display: inline-flex;
                width: 30px;
                height: 30px;
                align-items: center;
                font-size: 30px;
                flex-shrink: 0;
              }
            }

            .playback {
              width: 100%;
              overflow: hidden;
              margin-top: 24px;
              flex-direction: column;
              @include flexBox;
              align-items: flex-start;

              b {
                font-size: 16px;
                color: #2b2b2b;
                flex-shrink: 0;
              }

              .video-name {
                width: 100%;
                display: inline-block;
                margin-top: 20px;
                cursor: pointer;

                &:hover {
                  color: #6340c8;
                }
              }
            }
          }
        }
      }

      .lesson-info {
        width: 100%;
        height: 270px;
        margin-bottom: 28px;
        @include flexBox(space-between);

        .status {
          width: 270px;
          height: 100%;
          background: #f6f7fc;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 0 18px;

          h4 {
            width: 100%;
            height: 84px;
            color: #2b2b2b;
            box-sizing: border-box;
            border-bottom: 1px solid rgba($color: #bcbcbc, $alpha: 0.34);
            @include flexBox(center);
          }

          .student-statistics {
            width: calc(100% + 36px);
            margin-left: -18px;
            height: 186px;
            box-sizing: border-box;
            padding: 0 22px;
            @include flexBox;

            p {
              width: 50%;
              flex-direction: column;
              position: relative;
              @include flexBox(center);

              .pf_bold {
                font-size: 30px;
                color: #393939;
                line-height: 36px;
              }

              .tip {
                font-size: 16px;
                line-height: 36px;
                color: #2a2a2a;
              }

              &:first-child {
                &::after {
                  content: "";
                  height: 16px;
                  border-left: 1px solid rgba($color: #606060, $alpha: 0.26);
                  position: absolute;
                  bottom: 10px;
                  right: 0;
                }
              }
            }
          }
        }

        .lesson-time {
          width: 304px;
          height: 100%;
          background: #f0f3fa;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 0 18px;
          color: #2b2b2b;
          line-height: 24px;
          flex-direction: column;
          @include flexBox(flex-start);

          .day {
            width: 100%;
            height: 84px;
            font-size: 16px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba($color: #bcbcbc, $alpha: 0.34);
            @include flexBox(center);
          }

          .time {
            color: #464646;
            margin: 4px 0 22px;
          }

          h4 {
            margin-top: 42px;
          }
        }
      }

      .practice-state {
        width: 100%;
        height: calc(100% - 328px);
        border-radius: 10px;
        background: #f0f3fa;

        .probability {
          width: 100%;
          height: 230px;
          box-sizing: border-box;
          padding: 0 30px;
          flex-wrap: wrap;
          @include flexBox(space-between);

          .progress-stroke {
            position: relative;
            width: 102px;
            height: 140px;
            margin-left: 48px;

            .center-text {
              width: 100%;
              height: 102px;
              position: absolute;
              left: 0;
              top: 0;
              @include flexBox(center);

              .percentage {
                font-size: 20px;
                color: #f66478;
                line-height: 1em;

                span {
                  font-size: 14px;
                }
              }
            }

            .label {
              font-size: 16px;
              line-height: 24px;
              margin-top: 14px;
              text-align: center;
              color: #444;
            }
          }

          .contrast {
            width: 290px;
            margin-right: 22px;
            position: relative;
            @include flexBox(space-between);

            p {
              flex-direction: column;
              @include flexBox(center);

              .pf_bold {
                font-size: 30px;
                color: #393939;
                line-height: 36px;
              }

              .tip {
                font-size: 16px;
                line-height: 36px;
                color: #2a2a2a;
              }

              .yoy {
                color: #2ac293;

                .iconfont {
                  font-size: 16px;
                }

                &.down {
                  color: #f66478;
                }
              }
            }

            &::after {
              content: "";
              height: 16px;
              border-left: 1px solid rgba($color: #606060, $alpha: 0.26);
              position: absolute;
              bottom: 28px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          .velocity {
            flex-shrink: 0;
            width: 100%;
            height: 56px;
            background: #f6f7fc;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 0 26px;
            @include flexBox;

            b {
              font-size: 16px;
              color: #393939;
            }

            span {
              color: #2ac293;
              margin-left: 4px;

              .iconfont {
                font-size: 16px;
              }

              &.down {
                color: #f66478;
              }
            }
          }
        }

        .students {
          width: calc(100% - 12px);
          height: calc(100% - 302px);
          box-sizing: border-box;
          padding: 0 30px;
          overflow: hidden;
          overflow-y: auto;

          li {
            width: 100%;
            height: 108px;
            box-sizing: border-box;
            padding-left: 30px;
            padding-bottom: 4px;
            border-bottom: 1px dashed rgba($color: #7e7e7e, $alpha: 0.47);
            color: #2b2b2b;
            @include flexBox;

            &:last-child {
              border-bottom: none;
            }

            .pf_bold {
              font-size: 24px;
              margin-right: 14px;
            }

            .name {
              font-size: 16px;
              margin-left: 10px;
            }

            .answer-info {
              margin-left: auto;
              flex-direction: column;
              @include flexBox(center, flex-end);

              span {
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    .no-search {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: #fff;
      flex-direction: column;
      @include flexBox(center);

      img {
        width: 200px;
        margin-bottom: 20px;
      }
    }
  }
  .plate-group {
    margin-top: 30px;
    flex-wrap: wrap;
    @include flexBox(space-between);
    &.preview {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      .plate {
        width: 100%;
        height: auto;
        max-height: 336px;
        padding-bottom: 14px;
        box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.0959);
        .inner-box,
        .courseware--info {
          min-height: 160px;
          .no-data--empty {
            img {
              width: 120px;
            }
            p {
              line-height: 24px;
            }
          }
        }
        .courseware--info {
          min-height: 136px;
        }
        &:not(.courseware) {
          .inner-box {
            height: auto;
            max-height: 264px;
            .list-wrapper {
              height: auto;
              max-height: 264px;
            }
          }
        }
      }
    }
    .plate {
      width: calc((100% - 24px) / 2);
      height: 322px;
      border-radius: 10px;
      background: #fff;
      margin-bottom: 24px;
      @include contentNoData;

      &:not(.courseware) {
        .inner-box {
          width: 100%;
          height: calc(100% - 72px);
          box-sizing: border-box;
          padding: 12px 48px;

          .list-wrapper {
            width: calc(100% + 32px);
            height: 100%;
            box-sizing: border-box;
            padding-right: 32px;
            overflow: hidden;
            overflow-y: auto;

            li {
              height: 60px;
              font-size: 16px;
              color: #2e2e2e;
              box-sizing: border-box;
              border-top: 1px dashed rgba($color: #2e2e2e, $alpha: 0.17);
              overflow: hidden;
              @include flexBox(space-between);
              .title {
                display: flex;
                align-items: center;
                width: 280px;
                p {
                  flex: 1;
                }
                img{
                  max-height: 50px !important;
                }
              }
              &:first-child {
                border-top: none;
              }
            }
          }

          .homework-title {
            height: 60px;
            box-sizing: border-box;
            border-bottom: 1px dashed rgba($color: #2e2e2e, $alpha: 0.17);
            @include flexBox;

            p {
              flex-grow: 1;
              font-size: 16px;
              color: #2e2e2e;
              color: #2e2e2e;
              white-space: nowrap; 
              overflow: hidden;             
              text-overflow: ellipsis;    
              width: 200px; 
            }
          }

          .homework-desc {
            width: calc(100% + 32px);
            height: calc(100% - 78px);
            box-sizing: border-box;
            padding-right: 32px;
            overflow: hidden;
            overflow-y: auto;
            margin-top: 18px;
            line-height: 30px;
            color: #2e2e2e;
            word-break: break-word;
          }

          .btns {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }
      }
      &.public-prepare {
        margin-bottom: 0;
        width: 100%;
        height: 500px;
        .prepare-from {
          width: 264px;
          height: 68px;
          position: relative;
          margin-left: 60px;
          @include flexBox;

          &:after {
            content: "";
            width: 38px;
            height: 5px;
            border-radius: 2.5px;
            background: #58419c;
            position: absolute;
            left: 47px;
            bottom: 0;
            @include defaultAni;
          }

          &.platform::after {
            transform: translateX(132px);
          }

          li {
            width: 132px;
            cursor: pointer;
            color: #373639;
            padding: 0 52px;

            &:last-child {
              padding-left: 52px;
              position: relative;

              &::after {
                content: "";
                height: 20px;
                border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            &.current,
            &:not(.current):hover {
              color: #6c4ecb;
            }
          }
        }
        .inner-outer {
          width: 100%;
          height: calc(100% - 72px);
          box-sizing: border-box;
          padding: 20px 48px;
          .inner-box {
            width: 100%;
            height: calc(100% - 84px);
            margin-bottom: 10px;
            padding: 0;
            .quote-list {
              width: calc(100% + 60px);
              margin-left: -30px;
              box-sizing: border-box;
              padding: 0 30px;
              @include flexBox(space-between);
              flex-wrap: wrap;
              align-items: flex-start;
              li {
                width: 32%;
                height: 160px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                border-radius: 10px;
                background: #f6f7fc;
                box-sizing: border-box;
                padding: 10px 20px;
                border: none;
                margin-bottom: 20px;
                .prepare-info {
                  p {
                    color: #3c3b3b;
                    line-height: 36px;
                  }
                }
                .btns {
                  margin: 18px 0 0 auto;
                }
                &:hover {
                  box-shadow: 0px 0px 20px -2px rgba(87, 87, 87, 0.15);
                }
              }
              &:after {
                content: "";
                width: 32%;
              }
            }
          }
        }
      }
    }
    .courseware {
      &-inner {
        width: 100%;
        height: calc(100% - 72px);
        box-sizing: border-box;
        padding: 24px 28px 0;

        .foot-operation {
          height: 70px;
          box-sizing: border-box;
          border-top: 1px solid rgba($color: #e1dced, $alpha: 0.8);
          @include flexBox(flex-end);

          ::v-deep .el-upload {
            &--text {
              height: 38px;
              margin-left: 10px;
            }
          }
        }
      }

      &--info {
        width: 100%;
        height: calc(100% - 70px);
        flex-direction: column;
        @include flexBox(flex-start, flex-start);

        .info-data {
          height: calc(100% - 68px);
          margin-bottom: 18px;

          .file-title {
            font-size: 18px;
            @include flexBox;
            align-items: flex-start;

            .iconfont {
              display: inline-flex;
              width: 28px;
              height: 28px;
              align-items: center;
              font-size: 28px;
              color: rgba($color: #44269a, $alpha: 0.21);
              flex-shrink: 0;
            }

            span {
              display: inline-block;
              line-height: 28px;
              flex-grow: 1;
              color: #303030;
              margin-left: 4px;
              @include ellipsisMultiline;
            }
          }

          .upload-time {
            line-height: 24px;
            color: #474747;
            box-sizing: border-box;
            padding-left: 32px;
            margin-top: 20px;
          }
        }

        .el-button {
          margin-left: auto;
        }

        .no-data--empty {
          margin-top: -10px;
        }
      }
    }
  }
  .preview-prepare {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: auto;
  }
}

.popover-contianer {
  box-sizing: border-box;
  padding: 18px 5px 2px;

  ::v-deep {
    .el-form-item {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;

        .el-form-item__content {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }
}

.dialog {
  &-search {
    box-sizing: border-box;
    padding: 38px 0;

    .grade {
      width: 726px;
      margin: 0 auto 44px;
      @include flexBox(space-between);

      .el-select {
        width: 360px;
      }
    }

    .curriculum {
      height: 50vh;
      max-height: 530px;
      @include flexBox(space-between);

      .chapter,
      .sections {
        width: calc((100% - 6px) / 2);
        height: 100%;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px 6px 14px 30px;

        h5 {
          height: 46px;
          box-sizing: border-box;
          padding-right: 18px;
          margin-bottom: 16px;
          @include flexBox(space-between);

          span {
            flex-shrink: 0;
            margin-right: 10px;
          }
        }

        ::v-deep {
          .el-input {
            flex-grow: 1;

            &__inner {
              background: #fff;
              padding-right: 60px;
            }

            &__suffix {
              .iconfont {
                color: #6c4ecb;
              }

              .el-input__clear {
                margin-right: -50px;
                position: relative;

                &::after {
                  content: "";
                  width: 1px;
                  height: 10px;
                  left: -2px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  background: #b6b6b6;
                }
              }
            }
          }
        }
      }

      .chapter {
        background: rgba($color: #feaf2f, $alpha: 0.05);
      }

      .sections {
        background: rgba($color: #6c4ecb, $alpha: 0.05);
      }

      &-item {
        height: calc(100% - 62px);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;

        li {
          line-height: 42px;
          margin-bottom: 20px;
          font-size: 16px;
          color: #383838;

          &:last-child {
            margin-bottom: 0;
          }

          &.current,
          &:not(.current):not(.not-data):hover {
            color: #6c4ecb;
            cursor: pointer;
          }

          &.not-data {
            height: 100%;
            flex-direction: column;
            @include flexBox(center);

            img {
              width: 125px;
            }

            p {
              color: #b6b6b6;
              margin-top: 6px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &-time {
    box-sizing: border-box;
    padding: 36px 66px;

    .dialog-head {
      width: 100%;
      height: 32px;
      margin-bottom: 36px;
      color: #484848;
      @include flexBox;

      .month {
        margin-left: auto;
        margin-right: 28px;
      }

      .el-button {
        min-width: 32px;
        padding: 0;
        margin-left: 2px;
      }
    }

    .week-box {
      position: relative;
      width: 100%;
      height: 460px;

      .iconfont {
        height: 42px;
        line-height: 42px;
        padding: 0;
        font-size: 58px;
        position: absolute;
        top: 50%;
        transform: translateY(calc(-50% - 48px));

        &.week-prev {
          left: -92px;
        }

        &.week-next {
          right: -92px;
        }
      }

      .week {
        &-wrapper {
          width: 100%;
          height: 100%;
        }

        &-head {
          width: 100%;
          height: 102px;
          @include flexBox;

          li {
            width: 154px;
            height: 100%;
            box-sizing: border-box;
            background: #f6f7fc;
            border: solid #e0e3f0;
            border-width: 1px 1px 1px 0;
            flex-direction: column;
            @include flexBox(center);

            &:first-child {
              border-top-left-radius: 10px;
              border-left-width: 1px;
            }

            &:last-child {
              border-top-right-radius: 10px;
            }

            p {
              font-size: 16px;
              line-height: 30px;
              color: #282828;
            }
          }
        }

        &-body {
          width: calc(100% + 6px);
          height: calc(100% - 102px);
          overflow: hidden;
          overflow-y: auto;
        }

        &-row {
          height: 102px;
          @include flexBox;

          &:first-child {
            .week-col {
              border-top-width: 0;
            }
          }

          &:last-child {
            .week-col {
              border-bottom-width: 1px;

              &:first-child {
                border-bottom-left-radius: 10px;
              }

              &:last-child {
                border-bottom-right-radius: 10px;
              }
            }
          }
        }

        &-col {
          width: 154px;
          height: 100%;
          background: #fafbfe;
          box-sizing: border-box;
          border: solid #e0e3f0;
          border-width: 1px 1px 0 0;
          cursor: pointer;
          line-height: 20px;
          transition: all 0s !important;

          div {
            width: 100%;
            height: 100%;
            flex-direction: column;
            @include flexBox(center);
          }

          .subject {
            line-height: 30px;
            margin-bottom: 6px;
            font-size: 16px;
          }

          .grade {
            color: #3a3939;
          }

          .time {
            color: #676767;
          }

          &:first-child {
            border-left-width: 1px;
          }

          &.oneself {
            background: rgba($color: #6340c8, $alpha: 0.2);
            border-left: 4px solid #6c4ecb;
            padding-bottom: 16px;

            .subject {
              color: #6340c8;
            }

            &.over {
              cursor: not-allowed;
            }
          }

          &.completed {
            background: rgba($color: #2ac293, $alpha: 0.3);
            border-left: 4px solid #2ac293;

            .subject {
              color: #2ac293;
            }
          }

          &.employ {
            background: rgba($color: #e6b15f, $alpha: 0.05);
            border-left: 4px solid #ff9971;

            div {
              position: relative;
            }

            .del {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 24px;
              position: absolute;
              top: 4px;
              right: 4px;
              color: #ff9971;
              transform: scale(1);

              &:hover {
                transform: scale(1.2);
              }
            }

            &.over {
              cursor: not-allowed;
            }
          }

          &.over {
            cursor: default;
          }

          &:not(.oneself) {
            *:not(p) {
              width: 100%;
              height: 100%;
            }

            .vacancy {
              flex-direction: column;
              opacity: 0;
              @include flexBox(center);

              * {
                pointer-events: none;
              }

              &.show {
                opacity: 1;
              }
            }
          }

          &:not(.oneself):not(.over):not(.completed).show,
          &:not(.oneself):not(.over):not(.completed):hover,
          &.employ {
            background: rgba($color: #e6b15f, $alpha: 0.05);
            border-left: 4px solid #ff9971;

            .subject {
              color: #ff9971;
            }

            .vacancy {
              opacity: 1;
            }
          }
        }
      }
      .time_quantum{
        width: 154px;
        height: 100%;
        background: #fafbfe;
        box-sizing: border-box;
        border-width: 1px 1px 0 0;
        cursor: pointer;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:1px solid #e0e3f0;
        border-top: none;
      }
    }
  }

  &-upload {
    box-sizing: border-box;
    padding-top: 40px;

    .el-form {
      box-sizing: border-box;
      padding: 0 20px 0 34px;

      ::v-deep {
        .form-upload .el-form-item__error {
          margin-top: -10px;
        }

        .el-upload {
          &--text {
            width: 150px;
            height: 100px;

            .el-upload-dragger .iconfont {
              margin-top: 24px;
              margin-bottom: 0;
            }
          }

          .tips {
            margin-top: -14px;
          }
        }
      }
    }

    .file-list {
      .file-item {
        height: 60px;
        margin-bottom: 10px;
        @include flexBox(space-between);

        &:last-child {
          margin-bottom: 0;
        }

        .iconfont-color {
          font-size: 52px;
          flex-shrink: 0;
          line-height: 60px;
          flex-shrink: 0;
        }

        .el-button {
          flex-shrink: 0;
          margin-bottom: 6px;
        }

        .name {
          color: #212122;
          flex-grow: 1;
          max-width: 328px;
          margin: 0 20px;
        }
      }
    }
  }

  &-knowledge {
    width: 432px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 0;
    color: #6b6b6b;

    &--title,
    &--content {
      width: 100%;
      min-height: 46px;
      border-radius: 10px;
      background: #f0f0f0;
      box-sizing: border-box;
      padding: 10px 25px;
      line-height: 26px;
      margin-bottom: 24px;
    }

    &--content {
      min-height: 100px;
      padding: 18px 25px;
    }
  }

  &-knowledge-form {
    box-sizing: border-box;
    padding: 34px 20px 48px 64px;

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }

  &-practise-form {
    box-sizing: border-box;
    padding: 28px 40px 40px;

    .form-wrapper {
      max-height: 500px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-right: 46px;
      overflow: hidden;
      overflow-y: auto;

      ::v-deep {
        .el-upload--text {
          width: auto;
        }
      }

      .img-uploader {
        min-width: 46px;
        height: 46px;
        margin-left: 12px;
        // overflow: hidden;

        ::v-deep .el-upload--text {
          height: 46px;
        }

        .img-box {
          height: 46px;
          position: relative;

          img {
            height: 100%;
            border-radius: 10px;
          }

          .del {
            width: 24px;
            height: 24px;
            background: #fff;
            position: absolute;
            top: -12px;
            right: -8px;
            font-size: 32px;
            border-radius: 50%;
            color: #f8516d;
            @include flexBox(center);
          }
        }

        .upload-wrapper {
          width: 46px;
          height: 46px;
          background: #2ac293;
          border-radius: 10px;
          color: #fff;
          font-size: 30px;
          @include flexBox(center);
        }
      }

      .form-item {
        width: 100%;
        @include flexBox;
        align-items: flex-start;

        .el-button {
          flex-shrink: 0;
          margin-left: 10px;
        }

        .el-form-item {
          flex-grow: 1;
        }
      }

      .question-data {
        ::v-deep .el-checkbox,
        .el-radio {
          &__inner {
            width: 20px;
            height: 20px;
          }

          &__label {
            padding-left: 8px;
            color: #2b2b2b;
          }
        }
      }

      .question-options {
        margin-top: 5px;

        .option {
          width: 100%;
          @include flexBox;

          .el-form-item {
            margin-bottom: 0;
            flex-grow: 1;
          }

          &-list {
            width: 100%;
          }

          &-no {
            font-size: 14px;
            margin-right: 10px;
            flex-shrink: 0;
          }

          .el-input {
            flex-grow: 1;
          }

          .el-button {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }

        .el-checkbox,
        .el-radio {
          height: 46px;
          line-height: 46px;
          margin: 12px 0 12px 24px;

          &-group {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
  }

  &-homework-form {
    box-sizing: border-box;
    padding: 46px 0 42px 0;

    .form-wrapper {
      width: calc(100% + 32px);
      box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 146px 0 56px;
      overflow: hidden;
      overflow-y: auto;

      .el-select {
        margin-bottom: 12px;
      }

      .homework-questions {
        width: calc(100% + 202px);
        margin-left: -56px;
        box-sizing: border-box;
        padding: 40px 136px 0 0;
        margin-top: 30px;
        border-top: 1px solid rgba($color: #9e9fa2, $alpha: 0.18);

        .dialog-question-list {
          margin-top: 0;
          width: 100%;

          .question--data h5 .el-image {
            margin-right: 0;
          }

          .question-item {
            position: relative;
          }
        }

        .operate-group {
          width: 114px;
          position: absolute;
          top: -4px;
          right: -120px;
          @include flexBox;
        }
      }
    }

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }

  &-question {
    box-sizing: border-box;
    padding: 40px 8px 40px 32px;

    &.detail,
    &-list {
      .dialog-question--head {
        width: 484px;
      }

      .question--data {
        margin-right: 40px;
      }
      .operate-group {
        width: 116px;
        flex-shrink: 0;
        margin-left: 10px;
        text-align: left;
      }

      .question,
      .subtopic {
        &-item {
          width: 100%;
          margin-bottom: 28px;
          @include flexBox;
          align-items: flex-start;

          &:last-child {
            margin-bottom: 0;
          }

          &.combination {
            .combination--title {
              font-size: 16px;
              color: #404040;
              line-height: 24px;
              margin: 18px 0;
            }
          }
        }

        &--num,
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--num {
          color: #1f1f1f;
          font-size: 16px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: left;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }

      .subtopic {
        &--num {
          font-size: 14px;
        }

        &--type {
          margin-right: 6px;
        }

        &--title {
          line-height: 24px;
          @include flexBox;
          align-items: baseline;

          span {
            text-align: left;
            margin-right: 10px;
          }

          .el-image {
            flex-shrink: 0;
            margin-top: -7px;
          }
        }

        &--options,
        &--knowledge,
        &--answer {
          width: 100%;
        }
      }
    }

    &--head {
      width: 432px;
      margin-left: 18px;
      box-sizing: border-box;
    }

    &--title,
    &--desc {
      width: 100%;
      min-height: 46px;
      border-radius: 10px;
      background: #f0f0f0;
      box-sizing: border-box;
      padding: 10px 25px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    &--desc {
      @include flexBox;
      align-items: flex-start;

      span {
        flex-shrink: 0;
        white-space: pre-wrap;
      }
    }

    &-list {
      margin-top: 28px;
    }

    &--practise {
      max-height: 540px;
      margin-left: -20px;
      box-sizing: border-box;
      padding-top: 8px;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox;
      align-items: baseline;

      .question {
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: left;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }
    }
  }

  &-scroll {
    width: calc(100% + 20px);
    height: calc(100vh - 260px);
    box-sizing: border-box;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
  }

  &-backplay {
    box-sizing: border-box;
    padding: 30px 0;
    flex-direction: column;
    @include flexBox(center);

    .upload-table {
      width: 150px;
    }

    .file-list {
      margin-top: 20px;

      .file-item {
        height: 60px;
        margin-bottom: 10px;
        @include flexBox(space-between);

        &:last-child {
          margin-bottom: 0;
        }

        .iconfont-color {
          font-size: 52px;
          flex-shrink: 0;
          line-height: 60px;
          flex-shrink: 0;
        }

        .el-button {
          flex-shrink: 0;
          margin-bottom: 6px;
        }

        .name {
          color: #212122;
          flex-grow: 1;
          max-width: 328px;
          margin: 0 20px;
        }
      }
    }
  }
}
.create-question {
  box-sizing: border-box;
  padding: 28px 40px 40px;

  .create-form {
    &-wrapper {
      height: 500px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-right: 46px;
      overflow: hidden;
      overflow-y: auto;

      ::v-deep {
        .el-upload--text {
          width: auto;
        }
      }

      .img-uploader {
        min-width: 46px;
        height: 46px;
        margin-left: 12px;

        ::v-deep .el-upload--text {
          height: 46px;
        }

        .img-box {
          height: 46px;
          position: relative;

          img {
            height: 100%;
            border-radius: 10px;
          }

          .del {
            width: 24px;
            height: 24px;
            background: #fff;
            position: absolute;
            top: -12px;
            right: -8px;
            font-size: 32px;
            border-radius: 50%;
            color: #f8516d;
            @include flexBox(center);
          }
        }

        .upload-wrapper {
          width: 46px;
          height: 46px;
          background: #2ac293;
          border-radius: 10px;
          color: #fff;
          font-size: 30px;
          @include flexBox(center);
        }
      }

      .form-item {
        width: 100%;
        @include flexBox;

        .el-button {
          flex-shrink: 0;
          margin-left: 10px;
        }

        .el-form-item {
          flex-grow: 1;
        }
      }

      .question-data {
        ::v-deep .el-checkbox,
        .el-radio {
          &__inner {
            width: 20px;
            height: 20px;
          }

          &__label {
            padding-left: 8px;
            color: #2b2b2b;
          }
        }
      }

      .compose-group .form-item {
        align-items: center;

        .el-form-item {
          margin-bottom: 0;
        }
      }

      .question-options {
        margin-top: 5px;

        .option {
          width: 100%;
          @include flexBox;

          .el-form-item {
            margin-bottom: 0;
            flex-grow: 1;
          }

          &-list {
            width: 100%;
          }

          &-no {
            font-size: 14px;
            margin-right: 10px;
            flex-shrink: 0;
          }

          .el-input {
            flex-grow: 1;
          }

          .el-button {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }

        .el-checkbox,
        .el-radio {
          height: 46px;
          line-height: 46px;
          margin: 10px 0 4px 24px;

          &-group {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }

      .subtopic-data {
        box-sizing: border-box;
        padding-left: 82px;
      }
    }
  }
}

::v-deep {
  .el-dialog__wrapper.calc {
    width: calc(100% - 600px);
    padding-left: 60px;
  }
  .el-dialog.is-fullscreen{
    margin-left: 124px;
    width: calc(100% - 124px);
  }
}
::v-deep .el-drawer__wrapper {
  z-index: 105 !important;
  .el-drawer__body {
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px 0;
  }
}
.mintitle img {
  width: 40px;
  height: 40px;
}

.courseware-view{
  width: 100%;
  height: calc(100vh - 122px);
  padding: 14px 0 !important;
  box-sizing: border-box;
  iframe{
    width: 100%;
    height: 100%
  }
}
.large-dialog {
  max-width: 1280px;
  min-width: 1000px;
  width: 80%;
}
.import-dialog {
  box-sizing: border-box;
  padding: 40px 20px 28px;

  .download-formwork {
    text-align: right;
    padding-top: 10px;
    margin-bottom: 18px;
  }

  .file-list {
    .file-item {
      height: 60px;
      margin-top: 20px;
      @include flexBox(space-between, flex-end);

      .iconfont-color {
        font-size: 52px;
        flex-shrink: 0;
        line-height: 60px;
        flex-shrink: 0;
      }

      .el-button {
        flex-shrink: 0;
        margin-bottom: 6px;
      }

      .name {
        color: #212122;
        flex-grow: 1;
        margin: 0 20px 10px;
      }
    }
  }
}

.mintitle {
  * {
    display: flex;
    align-items: center;
  }
  img {
    width: 40px;
    height: 40px;
  }
}

.questionDoc{
    width: 100%;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 90px;
    position: relative;
    .allquestion{
        width: 49%;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        height: 100%;
        overflow: auto;
        margin-right: 2%;
        box-sizing: border-box;
    }
    
    .checkedquestion{
        width: 49%;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
    }
    .questionDocOperation{
        position:absolute;
        left:0;
        width:100%;
        bottom: 0px;
        height: 70px;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    h3{
        font-size: 18px;
        line-height: 30px;
    }
    .hint{
        color: #666666;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .list{
        width:100%;
        .item{
            border:1px solid #DDDDDD;
            width:100%;
            padding:0 15px 15px;
            border-radius: 10px;
            box-sizing: border-box;
            margin-bottom: 20px;
            .headline{
                border-bottom:1px solid #DDDDDD;
                display: flex;
                align-items: center;
                height: 46px;
                .name{
                    font-size: 16px;
                    .bold{
                        min-width: 56px;
                        background: #6340c8;
                        border-radius: 12px 0px 12px 12px;
                        text-align: center;
                        color: #fff;
                        margin: 0 14px;
                        box-sizing: border-box;
                        padding: 0 9px;
                        font-size: 12px;
                        line-height: 20px;
                        font-weight: 300;
                        display: inline-block;
                    }
                }
                .delete{
                    margin-left: auto;
                    width: 27px;
                    line-height: 27px;
                    background-color: #FF0000;
                    color: #FFFFFF;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 14px;
                    opacity: 0.5;
                    &:hover{
                        cursor: pointer;
                        opacity:1;
                    }
                }
            }
            .realm{
                display: flex;
                align-items: baseline;
                padding:5px 0;
                margin-top: 20px;
                position: relative;
                margin-bottom: 5px;
                .xing{
                    color:#FF0000;
                    position: absolute;
                    left: -10px;
                    top: 4px;
                }
                .title{
                    color:#cccccc;
                    font-size: 12px;
                    margin-left: 5px;
                }
                .addoptin{
                    margin-left: auto;
                    color:#6340c8;
                    font-size: 14px;
                    i{
                        margin-right: 5px;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .topic{
                width: 100%;
                display: flex;
                align-items: center;
            }
            .tinymce{
                flex: 1;
                min-height: 46px;
                line-height: 30px;
                border-radius: 10px;
                font-size: 14px;
                color: #191919;
                box-sizing: border-box;
            }
            .tinymceval{
                background-color: #F6F7FA;
                flex: 1;
                line-height: 22px;
                border-radius: 5px;
                padding: 5px 20px;
            }
            .answers{
                width:100%;
                li{
                    width:100%;  
                    display: flex;
                    align-items: flex-start;    
                    margin-bottom: 10px;  
                    line-height: 32px;
                    .el-checkbox{
                        padding-right: 15px;
                        margin-top: 5px;
                    }      
                    .el-radio{
                        padding-right: 15px;
                        margin-top: 5px;
                    }      
                    ::v-deep .el-radio__label{
                        padding-left:0;
                    }
                    .title{
                        color:#666666;
                        font-size:16px;
                        margin-right: 5px;
                    }
                    .index{
                        margin-right: 5px;
                    }
                    i{
                        margin-left: 10px;
                        width: 27px;
                        line-height: 27px;
                        background-color: #FF0000;
                        color: #FFFFFF;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 14px;
                        opacity: 0.5;
                        &:hover{
                            cursor: pointer;
                            opacity:1;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
  <section class="instruct">
    <template v-if="!showQuestionDoc">
      <div class="page-head">
        <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
        <div class="head-wrapper">
          <breadcrumb />
        </div>
      </div>
      <div class="page-inner" :class="{ finish: teachData.tecla_status == 30 }">
        <template v-if="!$isEmpty(teachData) && !timeDialog">
          <!-- 课程未结束 -->
          <div class="wrapper" v-if="teachData.tecla_status != 30">
            <div class="wrapper-left">
              <!-- 上课信息 -->
              <div class="attend-class">
                <img src="@assets/images/empty_textbook.png" alt />
                <div class="info-wrapper">
                  <p class="subject">
                    <span class="bold">{{ teachData.sysub_name }}</span>
                    <el-switch
                      v-model="isPublicClass"
                      class="text-inner"
                      :active-value="1"
                      :inactive-value="2"
                      inactive-text="公开"
                      active-text="非公开"
                      @change="setClassPublic"
                    />
                  </p>
                  <div class="info-detail">
                    <p class="light">
                      上课内容：{{ teachData.sccou_cha_titles_name || "-" }}
                    </p>
                    <p class="light">
                      上课班级：{{ teachData.tecla_sccla_name || "-" }}
                    </p>
                    <p class="light">
                      上课时间：{{ teachData.sccla_sch_tim_starttime }}~{{
                        teachData.sccla_sch_tim_endtime
                      }}
                    </p>
                    <el-button
                      type="text"
                      class="primary search"
                      @click="showDialog('search')"
                    >
                      <i class="iconfont-color">&#xe600;</i>
                      <span class="bold">设置</span>
                      <i class="iconfont">&#xe611;</i>
                    </el-button>
                  </div>
                </div>
                <div class="start">
                  <el-button v-loading="attendLoad" v-if="teachData.tecla_status != 30" type="custom_primary" size="medium" @click="startTeach"  >开始上课</el-button>
                  <el-button @click="downloadLessons" type="custom_warning" size="medium" v-loading="downloadLessonskoading">下载课件</el-button>
                </div>
              </div>
              <!-- 备课信息 -->
              <ul class="plate-group">
                <!-- 课件 -->
                <li class="plate courseware" v-loading="contentLoad1">
                  <h4 class="box-head">
                    <span class="bold">课件</span>
                  </h4>
                  <div class="courseware-inner">
                    <div
                      class="courseware--info"
                      :class="{ 'no-data': !courseware.length }"
                    >
                      <template v-if="courseware.length">
                        <div class="info-data">
                          <p class="file-title">
                            <i class="iconfont">&#xe62f;</i>
                            <span
                              >{{ courseware[0].teles_cou_title }}.{{
                                courseware[0].teles_cou_type
                              }}</span
                            >
                          </p>
                          <p class="upload-time">
                            时间：{{
                              courseware[0].time_text | formatTime("YYYY年M月D日")
                            }}
                          </p>
                        </div>
                        <el-button
                          type="custom_danger"
                          size="mini"
                          plain
                          @click="deleteCourseware"
                          >删除</el-button
                        >
                      </template>
                      <div class="no-data--empty" v-show="!courseware.length">
                        <img src="@assets/images/no-data3.png" alt />
                        <p>暂无上传课件信息哦~</p>
                      </div>
                    </div>
                    <div class="foot-operation">
                      <el-button
                        type="custom_primary"
                        size="small"
                        @click="$router.push({ name: 'TEACHING_COURSEWARE' })"
                        >查找课件</el-button
                      >
                      <el-button
                        type="custom_warning"
                        size="small"
                        @click="showDialog('upload')"
                        >上传课件</el-button
                      >
                    </div>
                  </div>
                </li>
                <!-- 知识点 -->
                <li class="plate knowledge" v-loading="contentLoad2">
                  <h4 class="box-head">
                    <span class="bold">知识点</span>
                    <div class="knowledge_button">
                      <el-button
                        type="text"
                        class="primary"
                        @click="showDialog('knowledgeImport')"
                        >批量导入</el-button
                      >
                      <el-button
                        type="text"
                        class="primary"
                        @click="showDialog('knowledge')"
                        >新建 +</el-button
                      >
                    </div>
                  </h4>
                  <div
                    class="inner-box"
                    :class="{ 'no-data': !teachKnowledge.length }"
                  >
                    <ul class="list-wrapper" v-if="teachKnowledge.length">
                      <li
                        v-for="(item, index) in teachKnowledge"
                        :key="item.teles_kno_id"
                      >
                        <p class="line-text--1st">
                          {{ index + 1 }}. {{ item.teles_kno_title }}
                        </p>
                        <div class="btns">
                          <el-button
                            type="custom_danger"
                            size="mini"
                            plain
                            @click="delKnowledge(item.teles_kno_id)"
                            >删除</el-button
                          >
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="showDialog('knowledge', item)"
                            >编辑</el-button
                          >
                          <el-button
                            type="custom_success"
                            size="mini"
                            plain
                            @click="showDialog('knowledgeDetail', item)"
                            >查看</el-button
                          >
                        </div>
                      </li>
                    </ul>
                    <div class="no-data--empty" v-show="!teachKnowledge.length">
                      <img src="@assets/images/no-data3.png" alt />
                      <p>暂无相关知识点哦~</p>
                    </div>
                  </div>
                </li>
                <!-- 课堂练习 -->
                <li class="plate practise" v-loading="contentLoad3">
                  <h4 class="box-head">
                    <span class="bold">课堂练习</span>
                    <div class="practiseImport">
                      <el-button
                        type="text"
                        class="primary"
                        @click="showCutting=true"
                        >图片识别</el-button
                      >
                      <el-button
                        type="text"
                        class="primary"
                        @click="showDialog('practiseImport')"
                        >批量导入</el-button
                      >
                      <el-button
                        type="text"
                        class="primary"
                        @click="showDialog('practise')"
                        >新建 +</el-button
                      >
                    </div>
                  </h4>
                  <div
                    class="inner-box"
                    :class="{ 'no-data': !teachPractise.length }"
                  >
                    <ul class="list-wrapper">
                      <li
                        v-for="(item, index) in teachPractise"
                        :key="item.teles_pra_id"
                      >
                        <div class="title">
                          {{ index + 1 }}.
                          <el-image
                            style="width: 46px; height: 32px; margin: 0 5px"
                            v-if="item.teles_pra_title_image"
                            fit="cover"
                            :src="formatfile(item.teles_pra_title_image)"
                            :preview-src-list="[
                              formatfile(item.teles_pra_title_image),
                            ]"
                          />
                          <p class="line-text--1st">
                            <span
                              class="mintitle richflexcenter" 
                              style="height:60px;"
                              v-html="htmlEncode(item.teles_pra_title)"
                            ></span>
                          </p>
                        </div>
                        <div class="btns">
                          <el-button
                            type="custom_danger"
                            size="mini"
                            plain
                            @click="delPractise(item.teles_pra_id)"
                            >删除</el-button
                          >
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="showDialog('practise', item.teles_pra_id)"
                            >编辑</el-button
                          >
                          <el-button
                            type="custom_success"
                            size="mini"
                            plain
                            @click="
                              showDialog('practiseDetail', item.teles_pra_id)
                            "
                            >查看</el-button
                          >
                        </div>
                      </li>
                    </ul>
                    <div class="no-data--empty" v-show="!teachPractise.length">
                      <img src="@assets/images/no-data3.png" alt />
                      <p>暂无相关课堂练习哦~</p>
                    </div>
                  </div>
                </li>
                <!-- 作业 -->
                <li class="plate homework" v-loading="contentLoad4">
                  <h4 class="box-head">
                    <span class="bold">作业</span>
                    <el-button
                      v-if="
                        $isEmpty(teachHomework) || $isEmpty(prepareSchoolHomework)
                      "
                      type="text"
                      class="primary"
                      @click="showDialog('homework')"
                    >
                      新建 +
                    </el-button>
                  </h4>
                  <div
                    class="inner-box"
                    :class="{ 'no-data': $isEmpty(teachHomework) }"
                  >
                    <template v-if="!$isEmpty(teachHomework)">
                      <el-tag type="info">家庭作业</el-tag>
                      <div class="homework-title">
                        <p show-overflow-tooltip>{{ teachHomework.teles_hom_title }}</p>
                        <div class="btns">
                          <el-button
                            type="custom_danger"
                            size="mini"
                            plain
                            @click="delHomework(teachHomework.teles_hom_id)"
                            >删除</el-button
                          >
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="
                              showDialog('homework', teachHomework.teles_hom_id)
                            "
                            >编辑</el-button
                          >
                          <el-button
                            type="custom_success"
                            size="mini"
                            plain
                            @click="
                              showDialog(
                                'homeworkDetail',
                                teachHomework.teles_hom_id
                              )
                            "
                            >查看</el-button
                          >
                        </div>
                      </div>
                      <!-- <div
                        class="homework-desc"
                        v-html="
                          teachHomework.teles_hom_remark
                            .replace(/\n|\r\n/g, '<br>')
                            .replace(/ /g, '&nbsp;')
                        "
                      ></div> -->
                    </template>

                    <template v-if="!$isEmpty(prepareSchoolHomework)">
                      <el-tag type="info">校内作业</el-tag>
                      <div class="homework-title">
                        <p>{{ prepareSchoolHomework.teles_hom_title }}</p>
                        <div class="btns">
                          <el-button
                            type="custom_danger"
                            size="mini"
                            plain
                            @click="
                              delHomework(prepareSchoolHomework.teles_hom_id)
                            "
                            >删除</el-button
                          >
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="
                              showDialog(
                                'homework',
                                prepareSchoolHomework.teles_hom_id
                              )
                            "
                            >编辑</el-button
                          >
                          <el-button
                            type="custom_success"
                            size="mini"
                            plain
                            @click="
                              showDialog(
                                'homeworkDetail',
                                prepareSchoolHomework.teles_hom_id
                              )
                            "
                            >查看</el-button
                          >
                        </div>
                      </div>
                      <!--<div
                        class="homework-desc"
                        
                        v-html="
                          prepareSchoolHomework.tetea_hom_remark
                            .replace(/\n|\r\n/g, '<br>')
                            .replace(/ /g, '&nbsp;')
                        "
                      ></div>
                      -->
                    </template>
                    <div
                      class="no-data--empty"
                      v-show="
                        $isEmpty(teachHomework) && $isEmpty(prepareSchoolHomework)
                      "
                    >
                      <img src="@assets/images/no-data3.png" alt />
                      <p>暂无相关作业哦~</p>
                    </div>
                  </div>
                </li>
                <li class="plate public-prepare">
                  <div class="box-head">
                    <h4><span class="bold">引用备课</span></h4>
                    <ul
                      class="prepare-from"
                      :class="{ platform: preparesFrom == 2 }"
                    >
                      <li
                        :class="{ current: preparesFrom == 1 }"
                        @click="changePreparesFrom(1)"
                      >
                        学校
                      </li>
                      <li
                        :class="{ current: preparesFrom == 2 }"
                        @click="changePreparesFrom(2)"
                      >
                        平台
                      </li>
                    </ul>
                  </div>
                  <div class="inner-outer">
                    <div
                      class="inner-box"
                      :class="{ 'no-data': !preparesData.length }"
                      v-loading="quoteLoad"
                    >
                      <ul class="list-wrapper quote-list">
                        <li v-for="item in preparesData" :key="item.tetea_id">
                          <div class="prepare-info">
                            <p>授课教师：{{ item.teuse_name || "-" }}</p>
                            <p>创建时间：{{ item.create_time || "-" }}</p>
                          </div>
                          <div class="btns">
                            <el-button
                              type="custom_success"
                              size="mini"
                              plain
                              @click="getPreparesDetails(item.tetea_id)"
                              >查看</el-button
                            >
                            <el-button
                              type="custom_primary"
                              size="mini"
                              plain
                              :disabled="quote_doing"
                              @click="quotePrepare(item.tetea_id)"
                              >引用</el-button
                            >
                          </div>
                        </li>
                      </ul>
                      <div class="no-data--empty" v-show="!preparesData.length">
                        <img src="@assets/images/no-data3.png" alt />
                        <p>暂无相关备课数据哦~</p>
                      </div>
                    </div>
                    <!-- 分页控件 -->
                    <div class="custom-foot">
                      <customPagination
                        :current="preparesPage.pageIndex"
                        :total="preparesPage.total"
                        @pageChange="flippingPage"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="wrapper-right">
              <div class="lesson-time">
                <p class="day">
                  {{ (teachData.tecla_time * 1000) | formatTime("YYYY年M月D日") }}
                </p>
                <h4 class="bold">{{ teachData.sysub_name }}</h4>
                <p class="time">
                  {{ teachData.sccla_sch_tim_starttime }}~{{
                    teachData.sccla_sch_tim_endtime
                  }}
                </p>
                <el-button
                  type="custom_primary"
                  size="small"
                  @click="showDialog('time')"
                  >选择课程表</el-button
                >
              </div>
              <!-- 学生连接信息 -->
              <div class="students">
                <div class="statistics-board" v-if="submitList.AllNum">
                  <p>
                    <span>学生总人数：</span>
                    <span>
                      <b class="pf_bold">{{ submitList.AllNum }}</b
                      >人
                    </span>
                  </p>
                  <p>
                    <span>写字板学生人数：</span>
                    <span>
                      <b class="pf_bold">{{ submitList.haveBoardNum }}</b
                      >人
                    </span>
                  </p>
                  <!-- <p>
                    <span>待连接人数：</span>
                    <span>
                      <b class="pf_bold">{{submitList.waitNum}}</b>人
                    </span>
                  </p> -->
                </div>

                <ul class="student-list">
                  <li v-for="(item, index) in submitList.userList" :key="index">
                    <el-avatar :size="62" :src="item.stuser_image" />
                    <p class="name">{{ item.stuser_name }}</p>
                    <!-- <p class="connect-status connected">
                      <i></i>
                      <span>已连接</span>
                    </p> -->
                  </li>
                  <!-- <li>
                    <el-avatar :size="62"
                      src="https://img2.baidu.com/it/u=4074946124,1101646946&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" />
                    <p class="name">罗磊</p>
                    <p class="connect-status">
                      <i></i>
                      <span>未连接</span>
                    </p>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
          <!-- 课程已结束 -->
          <div class="wrapper" v-if="teachData.tecla_status == 30">
            <div class="wrapper-left">
              <!-- 上课信息 -->
              <div class="attend-class">
                <img src="@assets/images/empty_textbook.png" alt />
                <div class="info-wrapper">
                  <p class="subject">
                    <span class="bold">{{ teachData.sysub_name }}</span>
                  </p>
                  <div class="info-detail">
                    <p class="light">
                      内容：{{ teachData.sccou_cha_titles_name || "-" }}
                    </p>
                    <p class="light">
                      上课班级：{{ teachData.tecla_sccla_name || "-" }}
                    </p>
                    <p class="light">
                      上课时间：{{ teachData.sccla_sch_tim_starttime }}~{{
                        teachData.sccla_sch_tim_endtime
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <h4 class="box-head">
                <span class="bold">课堂回顾</span>
                <el-button
                  v-if="$isEmpty(courseware) || !courseware[0].teles_cou_video"
                  type="custom_primary"
                  size="small"
                  @click="backPlayDialog = true"
                  >上传视频</el-button
                >
              </h4>
              <!-- 备课信息 -->
              <ul class="plate-group">
                <!-- 课件 -->
                <li class="plate courseware">
                  <h5 class="bold">
                    课件：{{ ($isEmpty(courseware) && "-") || "" }}
                  </h5>
                  <div class="courseware-inner">
                    <div class="courseware--info">
                      <div class="info-data" v-if="!$isEmpty(courseware)">
                        <p class="file-title">
                          <i
                            class="iconfont-color"
                            v-html="fileType(courseware[0].teles_cou_type)"
                          ></i>
                          <span>{{ courseware[0].teles_cou_title || "-" }}</span>
                        </p>
                        <p class="playback" v-if="courseware[0].teles_cou_video">
                          <b>课堂回放：</b>
                          <span
                            class="video-name line-text--1st"
                            @click="$fileDownload(courseware[0].teles_cou_video)"
                          >
                            {{ courseware[0].teles_cou_video_name }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- 知识点 -->
                <li class="plate knowledge">
                  <h5 class="bold">知识点：</h5>
                  <div
                    class="inner-box"
                    :class="{ 'no-data': $isEmpty(teachKnowledge) }"
                  >
                    <ul class="list-wrapper" v-if="!$isEmpty(teachKnowledge)">
                      <li
                        v-for="(item, index) in teachKnowledge"
                        :key="item.teles_kno_id"
                      >
                        <p class="line-text--1st">
                          {{ index + 1 }}. {{ item.teles_kno_title }}
                        </p>
                        <div class="btns">
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="showDialog('knowledgeDetail', item)"
                            >详情</el-button
                          >
                        </div>
                      </li>
                    </ul>
                    <div class="no-data--empty" v-show="$isEmpty(teachKnowledge)">
                      <img src="@assets/images/no-data3.png" alt />
                      <p>暂无相关知识点哦~</p>
                    </div>
                  </div>
                </li>
                <!-- 课堂练习 -->
                <li class="plate practise">
                  <h5 class="bold">课堂练习：</h5>
                  <div
                    class="inner-box"
                    :class="{ 'no-data': $isEmpty(teachPractise) }"
                  >
                    <ul class="list-wrapper" v-if="!$isEmpty(teachPractise)">
                      <li
                        v-for="(item, index) in teachPractise"
                        :key="item.teles_pra_id"
                      >
                        <div class="title">
                          {{ index + 1 }}.
                          <el-image
                            style="width: 46px; height: 32px; margin: 0 5px"
                            v-if="item.teles_pra_title_image"
                            fit="cover"
                            :src="formatfile(item.teles_pra_title_image)"
                            :preview-src-list="[
                              formatfile(item.teles_pra_title_image),
                            ]"
                          />
                          <p class="line-text--1st">
                            <em
                              class="richflexcenter"
                              style="height: 60px"
                              v-html="htmlEncode(item.teles_pra_title)"
                            ></em>
                          </p>
                        </div>
                        <div class="btns">
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="
                              showDialog('practiseDetail', item.teles_pra_id)
                            "
                            >详情</el-button
                          >
                        </div>
                      </li>
                    </ul>
                    <div class="no-data--empty" v-show="$isEmpty(teachPractise)">
                      <img src="@assets/images/no-data3.png" alt />
                      <p>暂无相关课堂练习哦~</p>
                    </div>
                  </div>
                </li>
                <!-- 作业 -->
                <li class="plate homework">
                  <h5 class="bold">作业：</h5>
                  <div
                    class="inner-box"
                    :class="{ 'no-data': $isEmpty(teachHomework) }"
                  >
                    <template v-if="!$isEmpty(teachHomework)">
                      <div class="homework-title">
                        <p>{{ teachHomework.teles_hom_title }}</p>
                        <div class="btns">
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="
                              showDialog(
                                'homeworkDetail',
                                teachHomework.teles_hom_id
                              )
                            "
                            >详情</el-button
                          >
                        </div>
                      </div>
                      <!-- <div
                        class="homework-desc"
                        v-html="
                          teachHomework.teles_hom_remark
                            .replace(/\n|\r\n/g, '<br>')
                            .replace(/ /g, '&nbsp;')
                        "
                      ></div> -->
                    </template>

                    <template v-if="!$isEmpty(prepareSchoolHomework)">
                      <div class="homework-title">
                        <p>{{ prepareSchoolHomework.teles_hom_title }}</p>
                        <div class="btns">
                          <el-button
                            type="custom_primary"
                            size="mini"
                            plain
                            @click="
                              showDialog(
                                'homeworkDetail',
                                prepareSchoolHomework.teles_hom_id
                              )
                            "
                            >详情</el-button
                          >
                        </div>
                      </div>
                      <!-- <div
                        class="homework-desc"
                        v-html="
                        prepareSchoolHomework.teles_hom_remark
                            .replace(/\n|\r\n/g, '<br>')
                            .replace(/ /g, '&nbsp;')
                        "
                      ></div> -->
                    </template>
                    <div
                      class="no-data--empty"
                      v-show="
                        $isEmpty(teachHomework) && $isEmpty(prepareSchoolHomework)
                      "
                    >
                      <img src="@assets/images/no-data3.png" alt />
                      <p>暂无相关作业哦~</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="wrapper-right">
              <div class="lesson-info">
                <div class="status">
                  <h4 class="bold">课程已结束</h4>
                  <div class="student-statistics">
                    <p>
                      <span class="pf_bold">{{ boardList.classNum }}</span>
                      <span class="tip">班级人数</span>
                    </p>
                    <p>
                      <span class="pf_bold">{{ boardList.bordNum }}</span>
                      <span class="tip">手写板人数</span>
                    </p>
                  </div>
                </div>
                <div class="lesson-time">
                  <p class="day">
                    {{
                      (teachData.tecla_time * 1000) | formatTime("YYYY年M月D日")
                    }}
                  </p>
                  <h4 class="bold">{{ teachData.sysub_name }}</h4>
                  <p class="time">
                    {{ teachData.sccla_sch_tim_starttime }}~{{
                      teachData.sccla_sch_tim_endtime
                    }}
                  </p>
                  <el-button
                    type="custom_primary"
                    size="small"
                    @click="showDialog('time')"
                    >选择课程表</el-button
                  >
                </div>
              </div>
              <div class="practice-state">
                <h4 class="box-head">
                  <span class="bold">课堂练习情况</span>
                </h4>
                <div class="probability">
                  <div class="progress-stroke">
                    <el-progress
                      class="percentage-progress danger"
                      :width="102"
                      :stroke-width="10"
                      type="circle"
                      stroke-linecap="butt"
                      :percentage="boardList.succRate"
                      :show-text="false"
                    />
                    <div class="center-text">
                      <p class="pf_bold percentage">
                        {{ boardList.succRate }}
                        <span class="pf">%</span>
                      </p>
                    </div>
                    <p class="label">正确率</p>
                  </div>
                  <div class="contrast">
                    <p>
                      <span class="pf_bold">{{ boardList.submitNum }}</span>
                      <span class="tip">已提交人数</span>
                      <span
                        class="yoy"
                        :class="boardList.submitNumYoy < 0 ? 'down' : ''"
                      >
                        同比：{{ boardList.submitNumYoy }}%
                        <i class="iconfont" v-if="boardList.submitNumYoy < 0"
                          >&#xe71d;</i
                        >
                        <i class="iconfont" v-if="boardList.submitNumYoy > 0"
                          >&#xe643;</i
                        >
                      </span>
                    </p>
                    <p>
                      <span class="pf_bold">{{ boardList.avgErrorNum }}</span>
                      <span class="tip">平均每题答错人数</span>
                      <span
                        class="yoy"
                        :class="boardList.avgErrorNumYoy > 0 ? 'down' : ''"
                      >
                        同比：{{ boardList.avgErrorNumYoy }}%
                        <i class="iconfont" v-if="boardList.avgErrorNumYoy > 0"
                          >&#xe71d;</i
                        >
                        <i class="iconfont" v-if="boardList.avgErrorNumYoy < 0"
                          >&#xe643;</i
                        >
                      </span>
                    </p>
                  </div>
                  <p class="velocity">
                    <b>答题平均速度：{{ boardList.avgTimeformat }}</b>
                    <span>
                      同比增长{{ boardList.avgTimeYoy }}%
                      <i class="iconfont" v-if="boardList.avgTimeYoy > 0"
                        >&#xe71d;</i
                      >
                      <i class="iconfont" v-if="boardList.avgTimeYoy < 0"
                        >&#xe643;</i
                      >
                    </span>
                  </p>
                </div>
                <ul class="students">
                  <li v-for="(item, index) in boardList.userList" :key="index">
                    <span class="pf_bold">{{ index + 1 }}</span>
                    <el-avatar :size="62" :src="item.stuser_image" />
                    <p class="name">{{ item.stuser_name }}</p>
                    <p class="answer-info">
                      <span>用时：{{ item.parAllTimeFormat }}</span>
                      <span>答错：{{ item.errorNum }}题</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
        <div class="no-search" v-if="$isEmpty(teachData) && !timeDialog">
          <img src="@assets/images/no-data3.png" alt="" />
          <el-button
            type="custom_primary"
            size="small"
            @click="showDialog('time')"
            >选择上课时间</el-button
          >
        </div>
      </div>
    </template>
     <!-- 导入的试题数据 -->
     <div class="questionDoc" v-if="showQuestionDoc">
        <!-- 所有试题 -->
        <div class="allquestion">
            <h3>批量导入习题</h3>
            <div class="hint">成功识别 <span>{{succNum}}</span> 题</div>
            <div class="list">

                <div class="item" v-for="(item,index) in questionDoc" :key="index">
                    <div class="headline">
                        <div class="name">第{{index+1}}题<span class="bold">{{item.syque_typ_name}}</span></div>
                        <div class="delete"><i class="el-icon-delete" @click="deleteQuestionDocItem(index)"></i></div>
                    </div>
                    <div class="realm">
                        <span class="xing">*</span>
                        题目
                    </div>
                    <div class="topic">
                        <try-editor class="tinymce" v-model="item.teles_pra_title"></try-editor>
                    </div>
                    <template v-if="item.syque_typ_id==2">
                        <!-- 多选题目 -->
                        <div class="realm">
                            <span class="xing">*</span>
                            选项
                            <span class="title">勾选正确答案，多选题请勾选多个选项</span>
                            <div class="addoptin" @click="addDocQuestion(item,index)">
                                <i class="el-icon-circle-plus-outline"></i>添加选项
                            </div>
                        </div>
                        <ul class="answers">
                            <li v-for="(item2,index2) in item.question_item" :key="index2">
                                <el-checkbox v-model="item2.teles_pra_ite_isanswer"  :true-label="10" :false-label="20"></el-checkbox>
                                <div class="index">{{item2.teles_pra_ite_code}}.</div>
                                <try-editor class="tinymce" v-model="item2.teles_pra_ite_title"></try-editor>
                                <i class="el-icon-delete" @click="deleteDocQuestionItem(item,index2)"></i>
                            </li>
                        </ul>
                    </template>
                    <template v-if="item.syque_typ_id==1||item.syque_typ_id==5">
                        <!-- 单选题 -->
                        <div class="realm">
                            <span class="xing">*</span>
                            选项
                            <span class="title">勾选正确答案</span>
                            <div class="addoptin" @click="addDocQuestion(item,index)">
                                <i class="el-icon-circle-plus-outline"></i>添加选项
                            </div>
                        </div>
                        <ul class="answers">
                            <li v-for="(item2,index2) in item.question_item" :key="index2">
                                <el-radio v-model="item.answer" :label="item2.teles_pra_ite_code">
                                    <span></span>
                                </el-radio>
                                <div class="index">{{item2.teles_pra_ite_code}}.</div> 
                                <try-editor class="tinymce" v-model="item2.teles_pra_ite_title"></try-editor>
                                <i class="el-icon-delete" @click="deleteDocQuestionItem(item,index2)"></i>
                            </li>
                        </ul>
                    </template>
                    <template v-if="item.syque_typ_id==3||item.syque_typ_id==4||item.syque_typ_id==7||item.syque_typ_id==8">
                        <!-- 填空题 -->
                        <div class="realm">
                            <span class="xing">*</span>
                            答案
                            <div class="addoptin" @click="addDocQuestion(item,index)">
                                <i class="el-icon-circle-plus-outline"></i>添加答案
                            </div>
                        </div>
                        <ul class="answers">
                            <li v-for="(item2,index2) in item.question_item" :key="index2">
                                <div class="title">第{{index2+1}}空</div>
                                <try-editor class="tinymce" v-model="item2.teles_pra_ite_title"></try-editor>
                                <i class="el-icon-delete" @click="deleteDocQuestionItem(item,index2)"></i>
                            </li>
                        </ul>
                    </template>
                    <div class="realm">
                        解析
                    </div>
                    <div class="topic">
                        <try-editor class="tinymce" v-model="item.teles_pra_analysis"></try-editor>
                    </div>
                    <div class="realm">
                        困难度
                    </div>
                    <div class="difficulty">
                        <el-radio v-model="item.teles_pra_difficulty" :label="10">简单</el-radio>
                        <el-radio v-model="item.teles_pra_difficulty" :label="20">普通</el-radio>
                        <el-radio v-model="item.teles_pra_difficulty" :label="30">困难</el-radio>
                    </div>
                    <div class="realm">
                        绑定知识点
                    </div>
                    <el-select class="select" v-model="item.teles_kno_id" placeholder="请选择">
                      <el-option
                        v-for="item in teachKnowledge"
                        :key="item.teles_kno_id"
                        :label="item.teles_kno_title"
                        :value="item.teles_kno_id"
                      />
                    </el-select>

                  </div>
            </div>
        </div>
        <!-- 选中的试题 -->
        <div class="checkedquestion">
            <div class="list">
                <div class="item" v-for="(item,index) in questionDoc" :key="index">
                    <div class="headline">
                        <div class="name">第{{index+1}}题<span class="bold">{{item.syque_typ_name}}</span></div>
                    </div>
                    <div class="realm">
                        题目
                    </div>
                    <div class="topic">
                        <div class="tinymceval" v-html="htmlEncode(item.teles_pra_title)"></div>
                    </div>
                    <template v-if="item.syque_typ_id==2">
                        <!-- 多选题目 -->
                        <div class="realm">
                            选项
                        </div>
                        <ul class="answers">
                            <li v-for="(item2,index2) in item.question_item" :key="index2">
                                <el-checkbox v-model="item2.teles_pra_ite_isanswer"  :true-label="10" :false-label="20"></el-checkbox>
                                <div class="index">{{item2.teles_pra_ite_code}}.</div>
                                <div class="tinymceval" v-html="htmlEncode(item2.teles_pra_ite_title)"></div>
                                <i class="el-icon-delete"></i>
                            </li>
                        </ul>
                    </template>
                    <template v-if="item.syque_typ_id==1||item.syque_typ_id==5">
                        <!-- 单选题 -->
                        <div class="realm">
                            选项
                        </div>
                        <ul class="answers">
                            <li v-for="(item2,index2) in item.question_item" :key="index2">
                                <el-radio v-model="item.answer" :label="item2.teles_pra_ite_code">
                                <span></span>
                                </el-radio>
                                <div class="index">{{item2.teles_pra_ite_code}}.</div> 
                                <div class="tinymceval" v-html="htmlEncode(item2.teles_pra_ite_title)"></div>
                            </li>
                        </ul>
                    </template>
                    <template v-if="item.syque_typ_id==3||item.syque_typ_id==4||item.syque_typ_id==7||item.syque_typ_id==8">
                        <!-- 填空题 -->
                        <div class="realm">
                            答案
                        </div>
                        <ul class="answers">
                            <li v-for="(item2,index2) in item.question_item" :key="index2">
                                <div class="title">第{{index2+1}}空</div>
                                <div class="tinymceval" v-html="htmlEncode(item2.teles_pra_ite_title)"></div>
                            </li>
                        </ul>
                    </template>
                    <div class="realm">
                        解析
                    </div>
                    <div class="topic">
                        <div class="tinymceval" v-html="htmlEncode(item.teles_pra_analysis)"></div>
                    </div>
                    <div class="realm">
                        困难度
                    </div>
                    <div class="difficulty">
                        <el-radio v-model="item.teles_pra_difficulty" v-if="item.teles_pra_difficulty==10" :label="10">简单</el-radio>
                        <el-radio v-model="item.teles_pra_difficulty" v-if="item.teles_pra_difficulty==20" :label="20">普通</el-radio>
                        <el-radio v-model="item.teles_pra_difficulty" v-if="item.teles_pra_difficulty==30" :label="30">困难</el-radio>
                    </div>

                    <div class="realm">
                        绑定知识点
                    </div>
                    <el-select class="select" disabled v-model="item.teles_kno_id" placeholder="请选择">
                      <el-option
                        v-for="item in teachKnowledge"
                        :key="item.teles_kno_id"
                        :label="item.teles_kno_title"
                        :value="item.teles_kno_id"
                      />
                    </el-select>
                  </div>
                </div>
        </div>
        <div class="questionDocOperation">
            <el-button type="custom_primary" size="small" v-loading="submitQuestionDocload" @click="questionDocsubmit()">确 定</el-button>
            <el-button type="custom_warning" size="small" @click="showQuestionDoc=false">取 消</el-button>
        </div>
    </div>
    <!-- 选择上课时间 -->
    <el-dialog
      width="1000px"
      title="选择上课时间"
      :visible.sync="timeDialog"
      custom-class="large-dialog"
      :show-close="false"
      @close="hideDialog('time')"
    >
      <div class="dialog-time">
        <h3 class="dialog-head">
          <span class="bold">课程表</span>
          <span class="bold month"
            >{{ currentYear }}年{{ currentMonth }}月</span
          >
          <span>
            <el-button
              class="iconfont"
              type="custom_primary"
              size="mini"
              @click="handleChangeMonth(0)"
              >&#xe612;</el-button
            >
            <el-button
              class="iconfont"
              type="custom_primary"
              size="mini"
              @click="handleChangeMonth(1)"
              >&#xe613;</el-button
            >
          </span>
        </h3>
        <div class="week-box">
          <el-button
            class="iconfont week-prev"
            type="text"
            @click="handleChangeWeek(0)"
            >&#xe656;</el-button
          >
          <el-button
            class="iconfont week-next"
            type="text"
            @click="handleChangeWeek(1)"
            >&#xe8ab;</el-button
          >
          <div class="week-wrapper">
            <ul class="week-head">
              <li>
                <p>时间段</p>
              </li>
              <li v-for="(day, index) in weekDays" :key="index">
                <p>{{ day | formatTime("M月D日") }}</p>
                <p>周{{ week[index] }}</p>
              </li>
            </ul>
            <div class="week-body">
              <ul
                class="week-row"
                v-for="(row, r_index) in teacherLessons"
                :key="r_index"
              >
                <div class="time_quantum">
                  <p class="time">{{ row.time_text }}</p>
                </div>
                <li
                  class="week-col"
                  :class="
                    weekTrendsClass(row, col, `popover${r_index}_${c_index}`)
                  "
                  v-for="(col, c_index) in row.classedule"
                  :key="c_index"
                >
                  <!-- 内容填充课程单元格 -->
                  <div
                    v-if="!$isEmpty(col.data)"
                    @click="chooseTeachTime(col, row)"
                  >
                    <i
                      class="del iconfont"
                      v-if="col.data.is_type == 2 && col.state == 2"
                      @click.stop="clearTeachTime(col.data.tecla_id)"
                    >
                      &#xe640;
                    </i>
                    <p class="subject bold">{{ col.data.sysub_name }}</p>
                    <p class="grade">{{ col.data.grade_class }}</p>
                    <!-- <p class="time">{{ row.time_text }}</p> -->
                  </div>
                  <!-- 空白课程单元格 -->
                  <div
                    v-if="$isEmpty(col.data)"
                    @click="showPopover(`popover${r_index}_${c_index}`)"
                  >
                    <el-popover
                      v-if="
                        weekTrendsClass(
                          row,
                          col,
                          `popover${r_index}_${c_index}`
                        ).indexOf('over') == -1
                      "
                      :ref="`popover${r_index}_${c_index}`"
                      :placement="(c_index < 5 && 'right') || 'left'"
                      width="256"
                      trigger="click"
                    >
                      <div class="popover-contianer">
                        <el-form ref="lessonForm" :model="lessonForm">
                          <el-form-item>
                            <el-select
                              v-model="lessonForm.grade_class"
                              placeholder="请选择备课班级"
                              filterable
                              clearable
                              @change="getTeachSubject"
                            >
                              <el-option
                                v-for="item in classList"
                                :key="item.value"
                                :label="`${item.sccla_grade_name}${item.sccla_name}`"
                                :value="`${item.sccla_grade},${item.sccla_id}`"
                              />
                            </el-select>
                          </el-form-item>
                          <el-form-item>
                            <el-select
                              v-model="lessonForm.sysub_id"
                              placeholder="请选择授课科目"
                              filterable
                              clearable
                            >
                              <el-option
                                v-for="item in teachSubject"
                                :key="item.sysub_id"
                                :label="item.sysub_name"
                                :value="item.sysub_id"
                              />
                            </el-select>
                          </el-form-item>
                          <el-form-item>
                            <el-button
                              type="custom_info"
                              size="small"
                              @click="
                                changePopover = false;
                                hidePopover();
                              "
                            >
                              取 消
                            </el-button>
                            <el-button
                              type="custom_primary"
                              size="small"
                              :disabled="
                                !lessonForm.grade_class || !lessonForm.sysub_id
                              "
                              @click="chooseTeachTime(col, row)"
                            >
                              确 定
                            </el-button>
                          </el-form-item>
                        </el-form>
                      </div>
                      <div
                        class="vacancy"
                        :class="{
                          show:
                            currentPopover == `popover${r_index}_${c_index}`,
                        }"
                        slot="reference"
                      >
                        <p class="subject">去占课</p>
                        <p class="time">{{ row.time_text }}</p>
                      </div>
                    </el-popover>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 数据筛选弹窗 -->
    <el-dialog
      title="筛选内容"
      :visible.sync="searchDialog"
      width="940px"
      :show-close="false"
      @close="hideDialog('search')"
    >
      <div class="dialog-search">
        <div class="curriculum">
          <div class="chapter">
            <h5>
              <span class="bold">教材</span>
              <el-input
                v-model.trim="sccouKey"
                maxlength="100"
                placeholder="请输入关键字"
                clearable
                @change="get_school_course_list()"
                @clear="get_school_course_list()"
              >
                <span slot="suffix" class="iconfont">&#xe61c;</span>
              </el-input>
            </h5>
            <ul class="curriculum-item">
              <template v-if="sccouData && sccouData.length">
                <li
                  :class="{
                    current:
                      searchData.sccou &&
                      searchData.sccou.id == item.sccou_id,
                  }"
                  v-for="item in sccouData"
                  :key="item.sysub_id"
                  @click="
                    chooseSearchKey(
                      'sccou',
                      item.sccou_id,
                      item.title
                    )
                  "
                >
                  {{ item.title }}
                </li>
              </template>
              <li class="not-data" v-else>
                <img src="@assets/images/no-data2.png" alt />
                <p>暂无选项哦~</p>
              </li>
            </ul>
          </div>
          <div class="chapter">
            <h5>
              <span class="bold">章</span>
              <el-input
                v-model.trim="chapterKey"
                maxlength="100"
                placeholder="请输入关键字"
                clearable
                @change="getChapters(1)"
                @clear="getChapters(1)"
              >
                <span slot="suffix" class="iconfont">&#xe61c;</span>
              </el-input>
            </h5>
            <ul class="curriculum-item">
              <template v-if="chapterData && chapterData.length">
                <li
                  :class="{
                    current:
                      searchData.chaper &&
                      searchData.chaper.id == item.sccou_cha_id,
                  }"
                  v-for="item in chapterData"
                  :key="item.sysub_id"
                  @click="
                    chooseSearchKey(
                      'chaper',
                      item.sccou_cha_id,
                      item.sccou_cha_title
                    )
                  "
                >
                  {{ item.sccou_cha_title }}
                </li>
              </template>
              <li class="not-data" v-else>
                <img src="@assets/images/no-data2.png" alt />
                <p>暂无选项哦~</p>
              </li>
            </ul>
          </div>
          <div class="sections">
            <h5>
              <span class="bold">节</span>
              <el-input
                v-model.trim="jointKey"
                maxlength="100"
                placeholder="请输入关键字"
                clearable
                @change="getChapters(2)"
                @clear="getChapters(2)"
              >
                <span slot="suffix" class="iconfont">&#xe61c;</span>
              </el-input>
            </h5>
            <ul class="curriculum-item">
              <template v-if="jointData && jointData.length">
                <li
                  :class="{
                    current:
                      searchData.joint &&
                      searchData.joint.id == item.sccou_cha_id,
                  }"
                  v-for="item in jointData"
                  :key="item.sysub_id"
                  @click="
                    chooseSearchKey(
                      'joint',
                      item.sccou_cha_id,
                      item.sccou_cha_title
                    )
                  "
                >
                  {{ item.sccou_cha_title }}
                </li>
              </template>
              <li class="not-data" v-else>
                <img src="@assets/images/no-data2.png" alt />
                <p>暂无选项哦~</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="footer" class="foot-center">
        <el-button type="custom_info" size="small" @click="hideDialog('search')"
          >取 消</el-button
        >
        <el-button type="custom_primary" size="small" @click="setTeachChapter"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 上传课件 -->
    <el-dialog
      title="上传课件"
      :visible.sync="uploadDialog"
      width="558px"
      :show-close="false" 
      @close="hideDialog('upload')"
    >
      <div class="dialog-upload">
        <el-form
          ref="uploadForm"
          :model="uploadForm"
          label-width="68px"
          :rules="uploadRules"
        >
          <el-form-item label="课件标题" prop="teles_cou_title">
            <el-input
              v-model.trim="uploadForm.teles_cou_title"
              maxlength="100"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="课件描述" prop="teles_cou_remark">
            <el-input
              v-model.trim="uploadForm.teles_cou_remark"
              type="textarea"
              maxlength="200"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item class="form-upload" label="课件" prop="teles_cou_url">
            <el-upload
              class="upload-table"
              :action="$upload.fileAction"
              :headers="$upload.header"
              drag 
              :show-file-list="coursewareList.length==0" 
              :limit="1"
              :file-list="coursewareList" 
              accept=".ppt,.pptx,.pdf,.doc,.docx"
              :before-upload="uploadBefore"
              :on-success="uploadSuccess" 
              :on-error="()=>{uploaLoading=false}"
              :on-remove="()=>{uploaLoading=false}"
            >
              <p class="iconfont">&#xe603;</p>
              <p class="tips">
                拖拽或
                <span>点击上传</span>
              </p>
            </el-upload>
            <ul class="file-list">
              <li
                class="file-item"
                v-for="(item, index) in coursewareList"
                :key="index"
              >
                <span class="iconfont-color" v-html="fileType(item.type)" />
                <p class="name line-text--1st">{{ item.name }}</p>
                <el-button
                  class="iconfont mini"
                  type="custom_primary"
                  circle
                  @click="removeCoursewareFile"
                  >&#xe620;</el-button
                >
              </li>
            </ul>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="foot-center">
        <el-button type="custom_info" size="small" @click="hideDialog('upload')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="addCourseware"
          :disabled="coursewareLoad"
          v-loading="coursewareLoad"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 课堂练习试卷切题 -->
    <cuttingProblem valuekey="teles_kno_id" labelkey="teles_kno_title"  style="position: fixed;z-index: 1000;"  v-model="showCutting" :emphasis="teachKnowledge" :loadingall="loadingall" @hideimgup="showCutting=false" @submit="submitCuttingProblem"></cuttingProblem>
    <!-- 知识点 新建||编辑 -->
    <el-dialog
      :title="(knowledgeForm.teles_kno_id && '编辑知识点') || '新建知识点'"
      :visible.sync="knowledgeDialog"
      width="554px"
      :show-close="false"
      @close="hideDialog('knowledge')"
    >
      <div class="dialog-knowledge-form">
        <el-form
          label-width="40px"
          ref="knowledgeForm"
          :model="knowledgeForm"
          :rules="knowledgeRules"
        >
          <el-form-item label="标题" prop="teles_kno_title">
            <el-input
              v-model.trim="knowledgeForm.teles_kno_title"
              maxlength="100"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="内容" prop="teles_kno_content">
            <el-input
              v-model.trim="knowledgeForm.teles_kno_content"
              type="textarea"
              maxlength="2000"
              placeholder="请输入内容"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('knowledge')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="addKnowledge"
          :disabled="knowledgeLoad"
          v-loading="knowledgeLoad"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 知识点详情 -->
    <el-dialog
      :visible.sync="knowledgeDetailDialog"
      :class="{ calc: previewPrepare }"
      width="554px"
      title="知识点详情"
      @close="hideDialog('knowledgeDetail')"
    >
      <div class="dialog-knowledge">
        <p class="dialog-knowledge--title">
          {{ knowledgeDetail[`${detail_prefix}kno_title`] }}
        </p>
        <p class="dialog-knowledge--content">
          {{ knowledgeDetail[`${detail_prefix}kno_content`] }}
        </p>
      </div>
    </el-dialog>
    <!-- 课堂练习 新建||编辑 -->
    <el-dialog
      :title="(practiseForm.teles_pra_id && '编辑') || '新建'"
      :visible.sync="practiseDialog"
      width="900px"
      @close="hideDialog('practise')"
      :show-close="false"
    >
      <div class="create-question">
        <div class="create-form-wrapper">
          <el-form
            ref="practiseForm"
            :model="practiseForm"
            label-width="82px"
            :rules="practiseRules"
          >
            <el-form-item label="题干">
              <div class="form-item">
                <el-form-item prop="teles_pra_title">
                  <!-- <el-input
                    v-model.trim="practiseForm.teles_pra_title"
                    maxlength="100"
                    placeholder="请输入"
                  /> -->
                  <try-editor
                    class="tinymce"
                    v-model="practiseForm.teles_pra_title"
                  ></try-editor>
                </el-form-item>
                <!-- <el-upload
                  class="img-uploader"
                  :action="$upload.imgAction"
                  :headers="$upload.header"
                  :show-file-list="false"
                  :accept="$upload.imgAccept"
                  :before-upload="$beforImgUpload"
                  :on-success="
                    (res) => {
                      return uploadImgSuccess(
                        res,
                        'practiseForm.teles_pra_title_image'
                      );
                    }
                  "
                >
                  <div
                    class="img-box"
                    v-if="practiseForm.teles_pra_title_image"
                    @click.stop
                  >
                    <el-image
                      style="width: 46px; height: 46px"
                      :src="formatfile(practiseForm.teles_pra_title_image)"
                      :preview-src-list="[
                        formatfile(practiseForm.teles_pra_title_image),
                      ]"
                    />
                    <i
                      class="del iconfont"
                      @click.stop="
                        practiseForm = {
                          ...practiseForm,
                          teles_pra_title_image: '',
                        }
                      "
                      >&#xe63e;</i
                    >
                  </div>
                  <div class="upload-wrapper" v-else>
                    <i class="iconfont">&#xe63d;</i>
                  </div>
                </el-upload> -->
              </div>
            </el-form-item>
            <el-form-item label="是否组合题">
              <el-radio-group
                v-model="practiseForm.teles_pra_ismakeup"
                @input="checkIsCompose"
              >
                <el-radio :label="20">否</el-radio>
                <el-radio :label="10">是</el-radio>
              </el-radio-group>
              <!-- 组合题添加小题 -->
              <el-button
                v-if="practiseForm.teles_pra_ismakeup == 10"
                type="custom_primary"
                size="medium"
                @click="appendQues"
              >
                + 继续加题
              </el-button>
            </el-form-item>
            <div
              :class="{
                'compose-group': practiseForm.teles_pra_ismakeup == 10,
                'question-data': practiseForm.teles_pra_ismakeup == 20,
              }"
              v-for="(que, que_index) in practiseForm.option_arr"
              :key="que_index"
            >
              <!-- 组合题 -->
              <template v-if="practiseForm.teles_pra_ismakeup == 10">
                <el-form-item label="小题干">
                  <div class="form-item">
                    <el-form-item
                      :prop="
                        'option_arr.' + que_index + '.teles_children_title'
                      "
                      :rules="{
                        trigger: 'blur',
                        validator: function (rule, value, callback) {
                          if (
                            !que.teles_children_title &&
                            !que.teles_children_image
                          ) {
                            callback('请填写题干或者上传题干图片');
                          } else {
                            callback();
                          }
                        },
                      }"
                    >
                      <try-editor
                        class="tinymce"
                        v-model="que.teles_children_title"
                      ></try-editor>
                    </el-form-item>
                    <!-- <el-upload
                      class="img-uploader"
                      :action="$upload.imgAction"
                      :headers="$upload.header"
                      :show-file-list="false"
                      :accept="$upload.imgAccept"
                      :before-upload="$beforImgUpload"
                      :on-success="
                        (res) => {
                          return uploadImgSuccess(
                            res,
                            `practiseForm.option_arr.${que_index}.teles_children_image`
                          );
                        }
                      "
                    >
                      <div
                        class="img-box"
                        v-if="que.teles_children_image"
                        @click.stop=""
                      >
                        <el-image
                          style="width: 46px; height: 46px"
                          :src="formatfile(que.teles_children_image)"
                          :preview-src-list="[
                            formatfile(que.teles_children_image),
                          ]"
                        />
                        <i
                          class="del iconfont"
                          @click.stop="
                            que.teles_children_image = '';
                            $forceUpdate();
                          "
                          >&#xe63e;</i
                        >
                      </div>
                      <div class="upload-wrapper" v-else>
                        <i class="iconfont">&#xe63d;</i>
                      </div>
                    </el-upload> -->
                    <!-- 删除选项 -->
                    <el-button
                      class="iconfont mini"
                      type="custom_primary"
                      circle
                      @click="removeQues(que_index)"
                      v-if="
                        practiseForm.option_arr &&
                        practiseForm.option_arr.length > 1
                      "
                    >
                      &#xe620;
                    </el-button>
                  </div>
                </el-form-item>
                <!-- 小题数据 -->
                <div class="subtopic-data">
                  <!-- 题目类型 -->
                  <el-form-item
                    label="选择类型"
                    :prop="`option_arr.${que_index}.syque_typ_id`"
                    :rules="{
                      required: true,
                      message: '请选择题目类型',
                      trigger: 'change',
                    }"
                  >
                    <el-select
                      v-model="que.syque_typ_id"
                      placeholder="请选择"
                      @change="chooseQuesType($event, que_index)"
                    >
                      <template v-for="item in questionType">
                        <el-option
                          :key="item.syque_typ_id"
                          :label="item.syque_typ_name"
                          :value="item.syque_typ_id"
                          v-if="item.syque_typ_id != 14"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                  <!-- 选择题选项 -->
                  <el-form-item
                    label="选项"
                    v-show="isChoice(que.syque_typ_id) && que.syque_typ_id"
                  >
                    <div class="question-options">
                      <!-- 多选选项 -->
                      <el-checkbox-group
                        v-model="que.answer"
                        v-if="que.syque_typ_id == 2"
                      >
                        <ul class="option-list">
                          <li
                            v-for="(opt, opt_index) in que.question_item"
                            :key="opt_index"
                          >
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_title`"
                                :rules="{
                                  required: false,
                                  trigger: 'blur',
                                  validator: function (rule, value, callback) {
                                    if (
                                      !opt.teles_pra_ite_title &&
                                      !opt.teles_pra_ite_image
                                    ) {
                                      callback('请填写选项内容或者选项图片');
                                    } else {
                                      callback();
                                    }
                                  },
                                }"
                              >
                                <try-editor
                                  class="tinymce"
                                  v-model="opt.teles_pra_ite_title"
                                ></try-editor>
                              </el-form-item>
                              <!-- <el-upload
                                class="img-uploader"
                                :action="$upload.imgAction"
                                :headers="$upload.header"
                                :show-file-list="false"
                                :accept="$upload.imgAccept"
                                :before-upload="$beforImgUpload"
                                :on-success="
                                  (res) => {
                                    return uploadImgSuccess(
                                      res,
                                      `practiseForm.option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_image`
                                    );
                                  }
                                "
                              >
                                <div
                                  class="img-box"
                                  v-if="opt.teles_pra_ite_image"
                                  @click.stop
                                >
                                  <el-image
                                    style="width: 46px; height: 46px"
                                    :src="formatfile(opt.teles_pra_ite_image)"
                                    :preview-src-list="[
                                      formatfile(opt.teles_pra_ite_image),
                                    ]"
                                  />
                                  <i
                                    class="del iconfont"
                                    @click.stop="opt.teles_pra_ite_image = ''"
                                    >&#xe63e;</i
                                  >
                                </div>
                                <div class="upload-wrapper" v-else>
                                  <i class="iconfont">&#xe63d;</i>
                                </div>
                              </el-upload> -->
                              <!-- 删除选项 -->
                              <el-button
                                class="iconfont mini"
                                type="custom_primary"
                                circle
                                @click="removeQueOption(que_index, opt_index)"
                                v-if="
                                  que.question_item &&
                                  que.question_item.length > 2
                                "
                              >
                                &#xe620;
                              </el-button>
                            </div>
                            <el-checkbox :label="opt_index"
                              >设为正确答案</el-checkbox
                            >
                          </li>
                        </ul>
                      </el-checkbox-group>
                      <!-- 单选选项 -->
                      <el-radio-group v-model="que.answer" v-else>
                        <ul class="option-list">
                          <li
                            v-for="(opt, opt_index) in que.question_item"
                            :key="opt_index"
                          >
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_title`"
                                :rules="{
                                  required: false,
                                  trigger: 'blur',
                                  validator: function (rule, value, callback) {
                                    if (
                                      !opt.teles_pra_ite_title &&
                                      !opt.teles_pra_ite_image
                                    ) {
                                      callback('请填写选项内容或者选项图片');
                                    } else {
                                      callback();
                                    }
                                  },
                                }"
                              >
                                <try-editor
                                  class="tinymce"
                                  v-model="opt.teles_pra_ite_title"
                                ></try-editor>
                              </el-form-item>
                              <!-- <el-upload
                                class="img-uploader"
                                :action="$upload.imgAction"
                                :headers="$upload.header"
                                :show-file-list="false"
                                :accept="$upload.imgAccept"
                                :before-upload="$beforImgUpload"
                                :on-success="
                                  (res) => {
                                    return uploadImgSuccess(
                                      res,
                                      `practiseForm.option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_image`
                                    );
                                  }
                                "
                              >
                                <div
                                  class="img-box"
                                  v-if="opt.teles_pra_ite_image"
                                  @click.stop
                                >
                                  <el-image
                                    style="width: 46px; height: 46px"
                                    :src="formatfile(opt.teles_pra_ite_image)"
                                    :preview-src-list="[
                                      formatfile(opt.teles_pra_ite_image),
                                    ]"
                                  />
                                  <i
                                    class="del iconfont"
                                    @click.stop="opt.teles_pra_ite_image = ''"
                                    >&#xe63e;</i
                                  >
                                </div>
                                <div class="upload-wrapper" v-else>
                                  <i class="iconfont">&#xe63d;</i>
                                </div>
                              </el-upload> -->
                              <!-- 删除选项 -->
                              <el-button
                                class="iconfont mini"
                                type="custom_primary"
                                circle
                                @click="removeQueOption(que_index, opt_index)"
                                v-if="
                                  que.question_item &&
                                  que.question_item.length > 2
                                "
                              >
                                &#xe620;
                              </el-button>
                            </div>
                            <el-radio :label="opt_index">设为正确答案</el-radio>
                          </li>
                        </ul>
                      </el-radio-group>
                      <el-button
                        type="custom_primary"
                        size="medium"
                        @click="appendQueOption(que_index)"
                      >
                        + 继续添加项
                      </el-button>
                    </div>
                  </el-form-item>
                  <!-- 非选择题答案 -->
                  <el-form-item
                    v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id"
                    label="答案"
                    v-for="(opt, opt_index) in que.question_item"
                    :key="opt_index"
                    :prop="`option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_title`"
                    :rules="{
                      required: false,
                      trigger: 'blur',
                      validator: function (rule, value, callback) {
                        if (
                          !opt.teles_pra_ite_title &&
                          !opt.teles_pra_ite_image
                        ) {
                          callback('请填写答案');
                        }
                        callback();
                      },
                    }"
                  >
                    <try-editor
                      v-model="opt.teles_pra_ite_title"
                      placeholder="多项填空题答案使用英文 , 隔开"
                    ></try-editor>
                  </el-form-item>

                  <!-- 解析 -->
                  <el-form-item label="解析说明">
                    <try-editor v-model="que.teles_pra_analysis"></try-editor>
                  </el-form-item>

                  <!-- 知识点 -->
                  <el-form-item
                    label="绑定知识点"
                    :prop="`option_arr.${que_index}.teles_kno_id`"
                  >
                    <el-select v-model="que.teles_kno_id" placeholder="请选择">
                      <el-option
                        v-for="item in teachKnowledge"
                        :key="item.teles_kno_id"
                        :label="item.teles_kno_title"
                        :value="item.teles_kno_id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </template>
              <!-- 非组合题 -->
              <template v-if="practiseForm.teles_pra_ismakeup == 20">
                <!-- 题目类型 -->
                <el-form-item
                  label="选择类型"
                  :prop="`option_arr.${que_index}.syque_typ_id`"
                  :rules="{
                    required: true,
                    message: '请选择题目类型',
                    trigger: 'change',
                  }"
                >
                  <el-select
                    v-model="que.syque_typ_id"
                    placeholder="请选择"
                    @change="chooseQuesType($event, que_index)"
                  >
                    <template v-for="item in questionType">
                      <el-option
                        :key="item.syque_typ_id"
                        :label="item.syque_typ_name"
                        :value="item.syque_typ_id"
                        v-if="item.syque_typ_id != 14"
                      />
                    </template>
                  </el-select>
                </el-form-item>
                <!-- 选择题选项 -->
                <el-form-item
                  label="选项"
                  v-show="isChoice(que.syque_typ_id) && que.syque_typ_id"
                >
                  <div class="question-options">
                    <!-- 多选选项 -->
                    <el-checkbox-group
                      v-model="que.answer"
                      v-if="que.syque_typ_id == 2"
                    >
                      <ul class="option-list">
                        <li
                          v-for="(opt, opt_index) in que.question_item"
                          :key="opt_index"
                        >
                          <div class="option">
                            <span class="option-no">
                              {{ opt_index | changeEnChart }}
                            </span>
                            <el-form-item
                              :prop="`option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_title`"
                              :rules="{
                                required: false,
                                trigger: 'blur',
                                validator: function (rule, value, callback) {
                                  if (
                                    !opt.teles_pra_ite_title &&
                                    !opt.teles_pra_ite_image
                                  ) {
                                    callback('请填写选项内容或者选项图片');
                                  } else {
                                    callback();
                                  }
                                },
                              }"
                            >
                              <try-editor
                                class="tinymce"
                                v-model="opt.teles_pra_ite_title"
                              ></try-editor>
                            </el-form-item>
                            <!-- <el-upload
                              class="img-uploader"
                              :action="$upload.imgAction"
                              :headers="$upload.header"
                              :show-file-list="false"
                              :accept="$upload.imgAccept"
                              :before-upload="$beforImgUpload"
                              :on-success="
                                (res) => {
                                  return uploadImgSuccess(
                                    res,
                                    `practiseForm.option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_image`
                                  );
                                }
                              "
                            >
                              <div
                                class="img-box"
                                v-if="opt.teles_pra_ite_image"
                                @click.stop
                              >
                                <el-image
                                  style="width: 46px; height: 46px"
                                  :src="formatfile(opt.teles_pra_ite_image)"
                                  :preview-src-list="[
                                    formatfile(opt.teles_pra_ite_image),
                                  ]"
                                />
                                <i
                                  class="del iconfont"
                                  @click.stop="opt.teles_pra_ite_image = ''"
                                  >&#xe63e;</i
                                >
                              </div>
                              <div class="upload-wrapper" v-else>
                                <i class="iconfont">&#xe63d;</i>
                              </div>
                            </el-upload> -->
                            <!-- 删除选项 -->
                            <el-button
                              class="iconfont mini"
                              type="custom_primary"
                              circle
                              @click="removeQueOption(que_index, opt_index)"
                              v-if="
                                que.question_item &&
                                que.question_item.length > 2
                              "
                            >
                              &#xe620;
                            </el-button>
                          </div>
                          <el-checkbox :label="opt_index"
                            >设为正确答案</el-checkbox
                          >
                        </li>
                      </ul>
                    </el-checkbox-group>
                    <!-- 单选选项 -->
                    <el-radio-group v-model="que.answer" v-else>
                      <ul class="option-list">
                        <li
                          v-for="(opt, opt_index) in que.question_item"
                          :key="opt_index"
                        >
                          <div class="option">
                            <span class="option-no">
                              {{ opt_index | changeEnChart }}
                            </span>
                            <el-form-item
                              :prop="`option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_title`"
                              :rules="{
                                required: false,
                                trigger: 'blur',
                                validator: function (rule, value, callback) {
                                  if (
                                    !opt.teles_pra_ite_title &&
                                    !opt.teles_pra_ite_image
                                  ) {
                                    callback('请填写选项内容或者选项图片');
                                  }
                                  callback();
                                },
                              }"
                            >
                              <try-editor
                                class="tinymce"
                                v-model="opt.teles_pra_ite_title"
                              ></try-editor>
                            </el-form-item>
                            <!-- <el-upload
                              class="img-uploader"
                              :action="$upload.imgAction"
                              :headers="$upload.header"
                              :show-file-list="false"
                              :accept="$upload.imgAccept"
                              :before-upload="$beforImgUpload"
                              :on-success="
                                (res) => {
                                  return uploadImgSuccess(
                                    res,
                                    `practiseForm.option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_image`
                                  );
                                }
                              "
                            >
                              <div
                                class="img-box"
                                v-if="opt.teles_pra_ite_image"
                                @click.stop
                              >
                                <el-image
                                  style="width: 46px; height: 46px"
                                  :src="formatfile(opt.teles_pra_ite_image)"
                                  :preview-src-list="[
                                    formatfile(opt.teles_pra_ite_image),
                                  ]"
                                />
                                <i
                                  class="del iconfont"
                                  @click.stop="opt.teles_pra_ite_image = ''"
                                  >&#xe63e;</i
                                >
                              </div>
                              <div class="upload-wrapper" v-else>
                                <i class="iconfont">&#xe63d;</i>
                              </div>
                            </el-upload> -->
                            <!-- 删除选项 -->
                            <el-button
                              class="iconfont mini"
                              type="custom_primary"
                              circle
                              @click="removeQueOption(que_index, opt_index)"
                              v-if="
                                que.question_item &&
                                que.question_item.length > 2
                              "
                            >
                              &#xe620;
                            </el-button>
                          </div>
                          <el-radio :label="opt_index">设为正确答案</el-radio>
                        </li>
                      </ul>
                    </el-radio-group>
                    <el-button
                      type="custom_primary"
                      size="medium"
                      @click="appendQueOption(que_index)"
                    >
                      + 继续添加项
                    </el-button>
                  </div>
                </el-form-item>
                <!-- 非选择题答案 -->
                <el-form-item
                  v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id"
                  label="答案"
                  v-for="(opt, opt_index) in que.question_item"
                  :key="opt_index"
                  :prop="`option_arr.${que_index}.question_item.${opt_index}.teles_pra_ite_title`"
                  :rules="{
                    required: false,
                    trigger: 'blur',
                    validator: function (rule, value, callback) {
                      if (
                        !opt.teles_pra_ite_title &&
                        !opt.teles_pra_ite_image
                      ) {
                        callback('请填写答案');
                      }
                      callback();
                    },
                  }"
                >
                  <try-editor
                    v-model="opt.teles_pra_ite_title"
                    placeholder="多项填空题答案使用英文 , 隔开"
                  ></try-editor>
                </el-form-item>

                <!-- 解析 -->
                <el-form-item label="解析说明">
                  <try-editor v-model="que.teles_pra_analysis"></try-editor>
                </el-form-item>

                <!-- 知识点 -->
                <el-form-item
                  label="绑定知识点"
                  :prop="`option_arr.${que_index}.teles_kno_id`"
                >
                  <el-select v-model="que.teles_kno_id" placeholder="请选择">
                    <el-option
                      v-for="item in teachKnowledge"
                      :key="item.teles_kno_id"
                      :label="item.teles_kno_title"
                      :value="item.teles_kno_id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </div>
          </el-form>
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('practise')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="addPractise"
          :disabled="practiseLoad"
          v-loading="practiseLoad"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 课堂练习详情 -->
    <el-dialog
      :visible.sync="practiseDetailDialog"
      :class="{ calc: previewPrepare }"
      :width="previewPrepare?'1000px':'1500px'"
      title="课堂练习详情"
      @close="hideDialog('practiseDetail')"
    >
      <div class="dialog-question detail" v-loading="practiseDetailLoad">
        <div class="dialog-question--practise dialog-scroll">
          <span class="question--type">{{
            practiseDetail.syque_typ_name
          }}</span>
          <div class="question--data">
            <h5 class="bold">
              <span
                class="richinline"
                v-html="htmlEncode(practiseDetail[`${detail_prefix}pra_title`])"
              ></span>
              <el-image
                style="width: 60px; height: 38px"
                v-if="practiseDetail[`${detail_prefix}pra_title_image`]"
                :src="
                  formatfile(practiseDetail[`${detail_prefix}pra_title_image`])
                "
                fit="cover"
                :preview-src-list="[
                  formatfile(practiseDetail[`${detail_prefix}pra_title_image`]),
                ]"
              />
            </h5>
            <!-- 组合题 -->
            <template
              v-if="practiseDetail[`${detail_prefix}pra_ismakeup`] == 10"
            >
              <p class="combination--title">
                {{ practiseDetail[`${detail_prefix}pra_describe`] }}
              </p>
              <ol class="combination--subtopic">
                <li
                  class="subtopic-item"
                  v-for="(citem, cindex) in practiseDetail.option_arr"
                  :key="citem[`${detail_prefix}pra_id`]"
                >
                  <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                  <div class="subtopic--data">
                    <p class="subtopic--title">
                      <span
                        class="richinline"
                        v-html="htmlEncode(citem[`${detail_prefix}pra_title`])"
                      ></span>
                      <el-image
                        style="width: 60px; height: 38px"
                        v-if="citem[`${detail_prefix}pra_title_image`]"
                        :src="
                          formatfile(citem[`${detail_prefix}pra_title_image`])
                        "
                        fit="cover"
                        :preview-src-list="[
                          formatfile(citem[`${detail_prefix}pra_title_image`]),
                        ]"
                      />
                    </p>
                    <dl
                      class="subtopic--options"
                      v-if="
                        citem.syque_typ_id == 1 ||
                        citem.syque_typ_id == 2 ||
                        citem.syque_typ_id == 5
                      "
                    >
                      <dt
                        class="option"
                        v-for="o in citem.question_item"
                        :key="o[`${detail_prefix}pra_ite_id`]"
                      >
                        <span
                          >{{ o[`${detail_prefix}pra_ite_code`] }}.
                          <em
                            class="richinline"
                            v-html="htmlEncode(o[`${detail_prefix}pra_ite_title`])"
                          ></em>
                        </span>
                        <el-image
                          style="width: 54px; height: 32px"
                          v-if="o[`${detail_prefix}pra_ite_image`]"
                          :src="formatfile(o[`${detail_prefix}pra_ite_image`])"
                          fit="cover"
                          :preview-src-list="[
                            formatfile(o[`${detail_prefix}pra_ite_image`]),
                          ]"
                        />
                      </dt>
                    </dl>
                    <p
                      class="subtopic--knowledge"
                      v-if="citem[`${detail_prefix}kno_title`]"
                    >
                      {{ citem[`${detail_prefix}kno_title`] }}
                    </p>
                    <div
                      class="subtopic--answer"
                      v-if="
                        !$isEmpty(citem.sure_answer) ||
                        citem[`${detail_prefix}pra_analysis`]
                      "
                    >
                      <p class="answer" v-if="!$isEmpty(citem.sure_answer)">
                        答案：<span
                          class="richinline"
                          v-html="htmlEncode(citem.sure_answer.join(','))"
                        ></span>
                      </p>
                      <p
                        class="analysis richinline"
                        v-if="citem[`${detail_prefix}pra_analysis`]"
                        v-html="htmlEncode(citem[`${detail_prefix}pra_analysis`])"
                      ></p>
                    </div>
                  </div>
                </li>
              </ol>
            </template>
            <!-- 其他题型 -->
            <template v-else>
              <ul
                class="question--options"
                v-if="
                  practiseDetail.syque_typ_id == 1 ||
                  practiseDetail.syque_typ_id == 2 ||
                  practiseDetail.syque_typ_id == 5
                "
              >
                <li
                  class="option"
                  v-for="o in practiseDetail.option_arr[0].question_item"
                  :key="o[`${detail_prefix}pra_ite_id`]"
                >
                  <span
                    >{{ o[`${detail_prefix}pra_ite_code`] }}.
                    <em
                      class="richinline"
                      v-html="htmlEncode(o[`${detail_prefix}pra_ite_title`])"
                    ></em>
                  </span>
                  <el-image
                    style="width: 54px; height: 32px"
                    v-if="o[`${detail_prefix}pra_ite_image`]"
                    :src="formatfile(o[`${detail_prefix}pra_ite_image`])"
                    fit="cover"
                    :preview-src-list="[
                      formatfile(o[`${detail_prefix}pra_ite_image`]),
                    ]"
                  />
                </li>
              </ul>
              <p
                class="question--knowledge"
                v-if="practiseDetail.option_arr[0][`${detail_prefix}kno_title`]"
              >
                {{ practiseDetail.option_arr[0][`${detail_prefix}kno_title`] }}
              </p>
              <div class="question--answer">
                <p
                  class="answer"
                  v-if="!$isEmpty(practiseDetail.option_arr[0].sure_answer)"
                >
                  答案：<span
                    class="richinline"
                    v-html="htmlEncode(practiseDetail.option_arr[0].sure_answer.join(','))"
                  ></span>
                </p>
                <p
                  class="analysis richinline"
                  v-if="
                    practiseDetail.option_arr[0][`${detail_prefix}pra_analysis`]
                  "
                  v-html="
                    htmlEncode(practiseDetail.option_arr[0][`${detail_prefix}pra_analysis`])
                  "
                ></p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 作业 新建||编辑 -->
    <el-dialog
      :title="(homeworkForm.teles_hom_id && '编辑') || '新建'"
      :visible.sync="homeworkDialog"
      fullscreen
      :width="previewPrepare?'1000px':'1500px'"
      :show-close="false"
      @close="hideDialog('homework')"
    >
      <div class="dialog-homework-form">
        <div class="dialog-scroll">
          <div class="form-wrapper">
            <el-form
              ref="homeworkForm"
              :model="homeworkForm"
              label-width="82px"
              :rules="homeworkRules"
            >
              <el-form-item label="标题" prop="teles_hom_title">
                <el-input
                  v-model.trim="homeworkForm.teles_hom_title"
                  maxlength="100"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="描述" prop="teles_hom_remark">
                <el-input
                  v-model.trim="homeworkForm.teles_hom_remark"
                  type="textarea"
                  maxlength="200"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="类型" prop="teles_hom_type">
                <el-radio-group v-model="homeworkForm.teles_hom_type">
                  <el-radio :label="1">家庭作业</el-radio>
                  <el-radio :label="2">校内作业</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="选题">
                <el-select
                  v-model="homeworkForm.syque_typ_id"
                  placeholder="请选择题目类型"
                  @change="searchQues"
                >
                  <el-option
                    v-for="item in questionType"
                    :key="item.syque_typ_id"
                    :label="item.syque_typ_name"
                    :value="item.syque_typ_id"
                  />
                </el-select>
                <!-- <el-select
                  v-model="homeworkForm.que_id"
                  filterable
                  :remote="homeworkForm.syque_typ_id"
                  clearable
                  placeholder="请输入题目关键字"
                  v-loadMore="loadMoreQues"
                  :remote-method="filterQues"
                  @change="queSelect"
                >
                  <el-option
                    v-for="item in selectQues"
                    :key="item.teque_id"
                    :label="item.teque_title"
                    :value="item.teque_id"
                  />
                </el-select> -->
                <!-- 自定义下拉 queSelect 选中的题 valkey选中的key  title显示的文案 remoteMethod输入框输入的文字  reachBottom滚动触底  more是否更多-->
                <richSelect
                  style="margin-bottom: 12px"
                  :list="selectQues"
                  placeholder="请输入题目关键字"
                  v-model="homeworkForm.que_id"
                  @queSelect="queSelect"
                  @remoteMethod="filterQues"
                  @reachBottom="loadMoreQues"
                  :more="this.selectQues.length < this.selectQuesPage.total"
                ></richSelect>
                <div class="homework-questions" v-if="atPresentQues.teque_id">
                  <ul class="dialog-question-list">
                    <li class="question-item">
                      <span class="question--type">{{
                        atPresentQues.syque_typ_name
                      }}</span>
                      <div class="question--data">
                        <h5 class="bold">
                          <span
                            class="richinline"
                            v-html="htmlEncode(atPresentQues.teque_title)"
                          ></span>
                          <el-image
                            style="width: 60px; height: 38px"
                            v-if="atPresentQues.teque_title_image"
                            :src="formatfile(atPresentQues.teque_title_image)"
                            fit="cover"
                            :preview-src-list="[
                              formatfile(atPresentQues.teque_title_image),
                            ]"
                          />
                        </h5>
                        <!-- 组合题 -->
                        <template v-if="atPresentQues.teque_ismakeup == 10">
                          <p class="combination--title">
                            {{ atPresentQues.teque_describe }}
                          </p>
                          <ol class="combination--subtopic">
                            <li
                              class="subtopic-item"
                              v-for="(
                                citem, cindex
                              ) in atPresentQues.children_question"
                              :key="citem.teque_id"
                            >
                              <span class="subtopic--num"
                                >（{{ cindex + 1 }}）</span
                              >
                              <div class="subtopic--data">
                                <p class="subtopic--title">
                                  <span
                                    class="richinline"
                                    v-html="htmlEncode(citem.teque_title)"
                                  ></span>
                                  <el-image
                                    style="width: 60px; height: 38px"
                                    v-if="citem.teque_title_image"
                                    :src="formatfile(citem.teque_title_image)"
                                    fit="cover"
                                    :preview-src-list="[
                                      formatfile(citem.teque_title_image),
                                    ]"
                                  />
                                </p>
                                <dl
                                  class="subtopic--options"
                                  v-if="
                                    citem.syque_typ_id == 1 ||
                                    citem.syque_typ_id == 2 ||
                                    citem.syque_typ_id == 5
                                  "
                                >
                                  <dt
                                    class="option"
                                    v-for="o in citem.question_item"
                                    :key="o.teque_ite_id"
                                  >
                                    <span
                                      >{{ o.teque_ite_code }}.
                                      <span
                                        class="richinline"
                                        v-html="htmlEncode(o.teque_ite_title)"
                                      ></span
                                    ></span>
                                    <el-image
                                      style="width: 54px; height: 32px"
                                      v-if="o.teque_ite_image"
                                      :src="formatfile(o.teque_ite_image)"
                                      fit="cover"
                                      :preview-src-list="[
                                        formatfile(o.teque_ite_image),
                                      ]"
                                    />
                                  </dt>
                                </dl>
                                <p
                                  class="subtopic--knowledge"
                                  v-if="citem.teles_kno_title"
                                >
                                  {{ citem.teles_kno_title }}
                                </p>
                                <div class="subtopic--answer">
                                  <p
                                    class="answer"
                                    v-if="!$isEmpty(citem.sure_answer)"
                                  >
                                    答案：<span
                                      class="richinline"
                                      v-html="htmlEncode(citem.sure_answer.join(','))"
                                    ></span>
                                  </p>
                                  <p
                                    class="analysis"
                                    v-if="citem.teque_iteanalysis"
                                  >
                                    <span
                                      class="richinline"
                                      v-html="htmlEncode(citem.teque_iteanalysis)"
                                    ></span>
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ol>
                        </template>
                        <!-- 其他题型 -->
                        <template v-else>
                          <ul
                            class="question--options"
                            v-if="
                              atPresentQues.syque_typ_id == 1 ||
                              atPresentQues.syque_typ_id == 2 ||
                              atPresentQues.syque_typ_id == 5
                            "
                          >
                            <li
                              class="option"
                              v-for="o in atPresentQues.children_question"
                              :key="o.teque_ite_id"
                            >
                              <span
                                >{{ o.teque_ite_code }}.
                                <span
                                  class="richinline"
                                  v-html="htmlEncode(o.teque_ite_title)"
                                ></span
                              ></span>
                              <el-image
                                style="width: 54px; height: 32px"
                                v-if="o.teque_ite_image"
                                :src="formatfile(o.teque_ite_image)"
                                fit="cover"
                                :preview-src-list="[
                                  formatfile(o.teque_ite_image),
                                ]"
                              />
                            </li>
                          </ul>
                          <p
                            class="question--knowledge"
                            v-if="atPresentQues.teles_kno_title"
                          >
                            {{ atPresentQues.teles_kno_title }}
                          </p>
                          <div class="question--answer">
                            <p
                              class="answer"
                              v-if="!$isEmpty(atPresentQues.sure_answer)"
                            >
                              答案：<span
                                class="richinline"
                                v-html="htmlEncode(atPresentQues.sure_answer.join(','))"
                              ></span>
                            </p>
                            <p
                              class="analysis"
                              v-if="atPresentQues.teque_analysis"
                            >
                              <span
                                class="richinline"
                                v-html="htmlEncode(atPresentQues.teque_analysis)"
                              ></span>
                            </p>
                          </div>
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <el-button
                    type="custom_primary"
                    size="small"
                    @click="appendHomeworkQue"
                    >+添加题</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="homework-questions">
            <ul class="dialog-question-list">
              <li
                class="question-item"
                :class="{ combination: item.teque_ismakeup == 10 }"
                v-for="(item, index) in homeworkQues"
                :key="item.teque_id"
              >
                <span class="question--num"
                  >{{ (index + 1 > 9 && index + 1) || `0${index + 1} ` }}.</span
                >
                <span class="question--type">{{ item.syque_typ_name }}</span>
                <div class="question--data">
                  <h5 class="bold">
                    <span class="richinline" v-html="htmlEncode(item.teque_title)"></span>
                    <el-image
                      style="width: 60px; height: 38px"
                      v-if="item.teque_title_image"
                      :src="formatfile(item.teque_title_image)"
                      fit="cover"
                      :preview-src-list="[formatfile(item.teque_title_image)]"
                    />
                  </h5>
                  <!-- 组合题 -->
                  <template v-if="item.teque_ismakeup == 10">
                    <p class="combination--title">{{ item.teque_describe }}</p>
                    <ol class="combination--subtopic">
                      <li
                        class="subtopic-item"
                        v-for="(citem, cindex) in item.children_question"
                        :key="citem.teque_id"
                      >
                        <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                        <div class="subtopic--data">
                          <p class="subtopic--title">
                            <span
                              class="richinline"
                              v-html="htmlEncode(citem.teque_title)"
                            ></span>
                            <el-image
                              style="width: 60px; height: 38px"
                              v-if="citem.teque_title_image"
                              :src="formatfile(citem.teque_title_image)"
                              fit="cover"
                              :preview-src-list="[
                                formatfile(citem.teque_title_image),
                              ]"
                            />
                          </p>
                          <dl
                            class="subtopic--options"
                            v-if="
                              citem.syque_typ_id == 1 ||
                              citem.syque_typ_id == 2 ||
                              citem.syque_typ_id == 5
                            "
                          >
                            <dt
                              class="option"
                              v-for="o in citem.question_item"
                              :key="o.teque_ite_id"
                            >
                              <span
                                >{{ o.teque_ite_code }}.
                                <span
                                  class="richinline"
                                  v-html="htmlEncode(o.teque_ite_title)"
                                ></span
                              ></span>
                              <el-image
                                style="width: 54px; height: 32px"
                                v-if="o.teque_ite_image"
                                :src="formatfile(o.teque_ite_image)"
                                fit="cover"
                                :preview-src-list="[
                                  formatfile(o.teque_ite_image),
                                ]"
                              />
                            </dt>
                          </dl>
                          <p
                            class="subtopic--knowledge"
                            v-if="citem.teles_kno_title"
                          >
                            {{ citem.teles_kno_title }}
                          </p>
                          <div class="subtopic--answer">
                            <p
                              class="answer"
                              v-if="!$isEmpty(citem.sure_answer)"
                            >
                              答案：
                              <span
                                class="richinline"
                                v-html="htmlEncode(citem.sure_answer.join(','))"
                              ></span>
                            </p>
                            <p class="analysis" v-if="citem.teque_iteanalysis">
                              <span
                                class="richinline"
                                v-html="htmlEncode(citem.teque_iteanalysis)"
                              ></span>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </template>
                  <!-- 其他题型 -->
                  <template v-else>
                    <ul
                      class="question--options"
                      v-if="
                        item.syque_typ_id == 1 ||
                        item.syque_typ_id == 2 ||
                        item.syque_typ_id == 5
                      "
                    >
                      <li
                        class="option"
                        v-for="o in item.children_question"
                        :key="o.teque_ite_id"
                      >
                        <span
                          >{{ o.teque_ite_code }}.
                          <span
                            class="richinline"
                            v-html="htmlEncode(o.teque_ite_title)"
                          ></span
                        ></span>
                        <el-image
                          style="width: 54px; height: 32px"
                          v-if="o.teque_ite_image"
                          :src="formatfile(o.teque_ite_image)"
                          fit="cover"
                          :preview-src-list="[formatfile(o.teque_ite_image)]"
                        />
                      </li>
                    </ul>
                    <p class="question--knowledge" v-if="item.teles_kno_title">
                      {{ item.teles_kno_title }}
                    </p>
                    <div class="question--answer">
                      <p class="answer" v-if="!$isEmpty(item.sure_answer)">
                        答案：<span
                          class="richinline"
                          v-html="htmlEncode(item.sure_answer.join(','))"
                        ></span>
                      </p>
                      <p class="analysis" v-if="item.teque_analysis">
                        <span
                          class="richinline"
                          v-html="htmlEncode(item.teque_analysis)"
                        ></span>
                      </p>
                    </div>
                  </template>
                </div>
                <div class="operate-group iconfont">
                  <!-- 移除作业题 -->
                  <el-button
                    type="custom_primary"
                    circle
                    @click="removeHomeworkQue(index)"
                    >&#xe620;</el-button
                  >
                  <!-- 作业题排序调整 -->
                  <template v-if="homeworkQues.length > 1">
                    <!-- 上移 -->
                    <el-button
                      class="lighter"
                      type="custom_info"
                      circle
                      v-if="index > 0"
                      @click="changeSort('u', index)"
                      >&#xe657;</el-button
                    >
                    <!-- 下移 -->
                    <el-button
                      class="lighter"
                      type="custom_info"
                      circle
                      v-if="index < homeworkQues.length - 1"
                      @click="changeSort('d', index)"
                      >&#xe645;</el-button
                    >
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('homework')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="addHomework"
          :disabled="homeworkLoad"
          v-loading="homeworkLoad"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 作业详情 -->
    <el-dialog
      :visible.sync="homeworkDetailDialog"
      :class="{ calc: previewPrepare }"
      :width="previewPrepare?'1000px':'1500px'"
      title="作业详情"
      @close="hideDialog('homeworkDetail')"
    >
      <div class="dialog-question detail" v-if="!$isEmpty(homeworkDetail)">
        <div class="dialog-scroll">
          <div class="dialog-question--head">
            <p class="dialog-question--title">
              标题：<span>{{
                homeworkDetail[`${detail_prefix}hom_title`]
              }}</span>
            </p>
            <div class="dialog-question--desc">
              <span>描述：</span>
              <p v-if="homeworkDetail[`${detail_prefix}hom_remark`]"
                v-html="
                  homeworkDetail[`${detail_prefix}hom_remark`]
                    .replace(/\n|\r\n/g, '<br>')
                    .replace(/ /g, '&nbsp;')
                "
              ></p>
            </div>
          </div>
          <ul class="dialog-question-list">
            <li
              class="question-item"
              :class="{ combination: item.teque_ismakeup == 10 }"
              v-for="(item, index) in homeworkDetail.question"
              :key="item.teque_id"
            >
              <span class="question--num"
                >{{ (index + 1 > 9 && index + 1) || `0${index + 1} ` }}.</span
              >
              <span class="question--type">{{ item.syque_typ_name }}</span>
              <div class="question--data">
                <h5 class="bold">
                  <span class="richinline" v-html="htmlEncode(item.teque_title)"></span>
                  <el-image
                    style="width: 60px; height: 38px"
                    v-if="item.teque_title_image"
                    :src="formatfile(item.teque_title_image)"
                    fit="cover"
                    :preview-src-list="[formatfile(item.teque_title_image)]"
                  />
                </h5>
                <!-- 组合题 -->
                <template v-if="item.teque_ismakeup == 10">
                  <p class="combination--title">{{ item.teque_describe }}</p>
                  <ol class="combination--subtopic">
                    <li
                      class="subtopic-item"
                      v-for="(citem, cindex) in item.children_question"
                      :key="citem.teque_id"
                    >
                      <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                      <div class="subtopic--data">
                        <p class="subtopic--title">
                          <span
                            class="richinline"
                            v-html="htmlEncode(citem.teque_title)"
                          ></span>
                          <el-image
                            style="width: 60px; height: 38px"
                            v-if="citem.teque_title_image"
                            :src="formatfile(citem.teque_title_image)"
                            fit="cover"
                            :preview-src-list="[
                              formatfile(citem.teque_title_image),
                            ]"
                          />
                        </p>
                        <dl
                          class="subtopic--options"
                          v-if="
                            citem.syque_typ_id == 1 ||
                            citem.syque_typ_id == 2 ||
                            citem.syque_typ_id == 5
                          "
                        >
                          <dt
                            class="option"
                            v-for="o in citem.question_item"
                            :key="o.teque_ite_id"
                          >
                            <span
                              >{{ o.teque_ite_code }}.
                              <span
                                class="richinline"
                                v-html="htmlEncode(o.teque_ite_title)"
                              ></span
                            ></span>
                            <el-image
                              style="width: 54px; height: 32px"
                              v-if="o.teque_ite_image"
                              :src="formatfile(o.teque_ite_image)"
                              fit="cover"
                              :preview-src-list="[
                                formatfile(o.teque_ite_image),
                              ]"
                            />
                          </dt>
                        </dl>
                        <p
                          class="subtopic--knowledge"
                          v-if="citem[`${detail_prefix}kno_title`]"
                        >
                          {{ citem[`${detail_prefix}kno_title`] }}
                        </p>
                        <div
                          class="subtopic--answer"
                          v-if="
                            !$isEmpty(citem.sure_answer) || citem.teque_analysis
                          "
                        >
                          <p class="answer" v-if="!$isEmpty(citem.sure_answer)">
                            答案：<span
                              class="richinline"
                              v-html="htmlEncode(citem.sure_answer.join(','))"
                            ></span>
                          </p>
                          <p class="analysis" v-if="citem.teque_analysis">
                            <span
                              class="richinline"
                              v-html="htmlEncode(citem.teque_analysis)"
                            ></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ol>
                </template>
                <!-- 其他题型 -->
                <template v-else>
                  <ul
                    class="question--options"
                    v-if="
                      item.syque_typ_id == 1 ||
                      item.syque_typ_id == 2 ||
                      item.syque_typ_id == 5
                    "
                  >
                    <li
                      class="option"
                      v-for="o in item.children_question"
                      :key="o.teque_ite_id"
                    >
                      <span
                        >{{ o.teque_ite_code }}.<span
                          class="richinline"
                          v-html="htmlEncode(o.teque_ite_title)"
                        ></span
                      ></span>
                      <el-image
                        style="width: 54px; height: 32px"
                        v-if="o.teque_ite_image"
                        :src="formatfile(o.teque_ite_image)"
                        fit="cover"
                        :preview-src-list="[formatfile(o.teque_ite_image)]"
                      />
                    </li>
                  </ul>
                  <p
                    class="question--knowledge"
                    v-if="item[`${detail_prefix}kno_title`]"
                  >
                    {{ item[`${detail_prefix}kno_title`] }}
                  </p>
                  <div
                    class="question--answer"
                    v-if="!$isEmpty(item.sure_answer) || item.teque_analysis"
                  >
                    <p class="answer" v-if="!$isEmpty(item.sure_answer)">
                      答案：<span
                        class="richinline"
                        v-html="htmlEncode(item.sure_answer.join(','))"
                      ></span>
                    </p>
                    <p class="analysis" v-if="item.teque_analysis">
                      <span
                        class="richinline"
                        v-html="htmlEncode(item.teque_analysis)"
                      ></span>
                    </p>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="dialog-question detail" style="padding:180px 0;" v-loading="true"></div>
    </el-dialog>
     <!-- 作业详情 -->
     <el-dialog
      :visible.sync="prepareHomeworkDetailDialog"
      :class="{ calc: previewPrepare }"
      :width="previewPrepare?'1000px':'1500px'"
      title="作业详情"
      @close="hideDialog('prepareHomeworkDetail')"
    >
      <div class="dialog-question detail" v-if="!$isEmpty(homeworkDetail)">
        <div class="dialog-scroll">
          <div class="dialog-question--head">
            <p class="dialog-question--title">
              标题：<span>{{
                homeworkDetail[`${detail_prefix}hom_title`]
              }}</span>
            </p>
            <div class="dialog-question--desc">
              <span>描述：</span>
              <p v-if="homeworkDetail[`${detail_prefix}hom_remark`]"
                v-html="homeworkDetail[`${detail_prefix}hom_remark`].replace(/\n|\r\n/g, '<br>').replace(/ /g, '&nbsp;')"
              ></p>
            </div>
          </div>
          <ul class="dialog-question-list">
            <li
              class="question-item"
              :class="{ combination: item.teque_ismakeup == 10 }"
              v-for="(item, index) in homeworkDetail.question"
              :key="item.teque_id"
            >
              <span class="question--num"
                >{{ (index + 1 > 9 && index + 1) || `0${index + 1} ` }}.</span
              >
              <span class="question--type">{{ item.syque_typ_name }}</span>
              <div class="question--data">
                <h5 class="bold">
                  <span class="richinline" v-html="htmlEncode(item.scque_title)"></span>
                  <el-image
                    style="width: 60px; height: 38px"
                    v-if="item.scque_title_image"
                    :src="formatfile(item.scque_title_image)"
                    fit="cover"
                    :preview-src-list="[formatfile(item.scque_title_image)]"
                  />
                </h5>
                <!-- 组合题 -->
                <template v-if="item.scque_ismakeup == 10">
                  <p class="combination--title">{{ item.scque_describe }}</p>
                  <ol class="combination--subtopic">
                    <li
                        class="subtopic-item"
                        v-for="(citem, cindex) in item.children_question"
                        :key="citem.scque_id"
                    >
                      <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                      <div class="subtopic--data">
                        <p class="subtopic--title">
                          <span
                              class="richinline"
                              v-html="htmlEncode(citem.scque_title)"
                          ></span>
                          <el-image
                              style="width: 60px; height: 38px"
                              v-if="citem.scque_title_image"
                              :src="formatfile(citem.scque_title_image)"
                              fit="cover"
                              :preview-src-list="[
                              formatfile(citem.scque_title_image),
                            ]"
                          />
                        </p>
                        <dl
                            class="subtopic--options"
                            v-if="
                            citem.syque_typ_id == 1 ||
                            citem.syque_typ_id == 2 ||
                            citem.syque_typ_id == 5
                          "
                        >
                          <dt
                              class="option"
                              v-for="o in citem.question_item"
                              :key="o.scque_ite_id"
                          >
                            <span
                            >{{ o.scque_ite_code }}.
                              <span
                                  class="richinline"
                                  v-html="htmlEncode(o.scque_ite_title)"
                              ></span
                              ></span>
                            <el-image
                                style="width: 54px; height: 32px"
                                v-if="o.scque_ite_image"
                                :src="formatfile(o.scque_ite_image)"
                                fit="cover"
                                :preview-src-list="[
                                formatfile(o.scque_ite_image),
                              ]"
                            />
                          </dt>
                        </dl>
                        <p
                            class="subtopic--knowledge"
                            v-if="citem.tetea_kno_title"
                        >
                          {{ citem.tetea_kno_title }}
                        </p>
                        <div class="subtopic--answer">
                          <p class="answer" v-if="!$isEmpty(citem.sure_answer)">
                            答案：<span
                              class="richinline"
                              v-html="htmlEncode(citem.sure_answer.join(','))"
                          ></span>
                          </p>
                          <p class="analysis" v-if="citem.scque_analysis">
                            <span
                                class="richinline"
                                v-html="htmlEncode(citem.scque_analysis)"
                            ></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ol>
                </template>
                <!-- 其他题型 -->
                <template v-else>
                  <ul
                      class="question--options"
                      v-if="
                      item.syque_typ_id == 1 ||
                      item.syque_typ_id == 2 ||
                      item.syque_typ_id == 5
                    "
                  >
                    <li
                        class="option"
                        v-for="o in item.children_question"
                        :key="o.scque_ite_id"
                    >
                      <span class="flex">
                        {{ o.scque_ite_code }}.
                        <span
                            class="richinline"
                            v-html="htmlEncode(o.scque_ite_title)"
                        ></span
                        ></span>
                      <el-image
                          style="width: 54px; height: 32px"
                          v-if="o.scque_ite_image"
                          :src="formatfile(o.scque_ite_image)"
                          fit="cover"
                          :preview-src-list="[formatfile(o.scque_ite_image)]"
                      />
                    </li>
                  </ul>
                  <p class="question--knowledge" v-if="item.tetea_kno_title">
                    {{ item.tetea_kno_title }}
                  </p>
                  <div class="question--answer">
                    <p class="answer" v-if="!$isEmpty(item.sure_answer)">
                      答案：<span
                        class="richinline"
                        v-html="htmlEncode(item.sure_answer.join(','))"
                    ></span>
                    </p>
                    <p class="analysis" v-if="item.scque_analysis">
                      <span
                          class="richinline"
                          v-html="htmlEncode(item.scque_analysis)"
                      ></span>
                    </p>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="dialog-question detail" style="padding:180px 0;" v-loading="true"></div>
    </el-dialog>
    <!-- 课堂回放 -->
    <el-dialog
      :visible.sync="backPlayDialog"
      width="554px"
      @close="hideDialog('backPlay')"
      title="课堂回放"
    >
      <div class="dialog-backplay">
        <el-upload
          class="upload-table"
          :action="$upload.fileAction"
          :headers="$upload.header"
          drag 
          :show-file-list="backPlayList.length==0" 
          :file-list="backPlayList" 
          accept=".mp4,.avi,.wmv,.mpeg"
          :before-upload="$beforVideoUpload"
          :on-success="uploadBackPlaySuccess"
        >
          <p class="iconfont">&#xe603;</p>
          <p class="tips">
            拖拽或
            <span>点击上传</span>
          </p>
        </el-upload>
        <ul class="file-list">
          <li
            class="file-item"
            v-for="(item, index) in backPlayList"
            :key="index"
          >
            <span class="iconfont-color" v-html="fileType(item.type)" />
            <p class="name line-text--1st">{{ item.name }}</p>
            <el-button
              class="iconfont mini"
              type="custom_primary"
              circle
              @click="removeBackPlayFile"
              >&#xe620;</el-button
            >
          </li>
        </ul>
      </div>
      <div slot="footer" class="foot-center">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('backPlay')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          :disabled="$isEmpty(backPlayList)"
          size="small"
          @click="uploadBackPlay"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 知识点导入模板 -->
    <el-dialog
      title="导入"
      :visible.sync="knowledgeImportDialog" 
      width="560px"
      :show-close="false"
      @close="hideDialog('knowledgeImport')"
    >
      <div class="import-dialog">
        <p class="download-formwork">
          <el-button
            type="custom_primary"
            size="small"
            @click="downloadTemplate"
            >下载模板</el-button
          >
        </p>
        <el-upload
          :action="$upload.fileAction2"
          :headers="$upload.header"
          drag
          :show-file-list="fileList.length==0" 
          :file-list="fileList" 
          accept=".xls,.xlsx,.xlsm"
          :limit="1"
          :before-upload="uploadknowledgeImportupload"
          :on-success="uploadknowledgeImportSuccess" 
          :on-error="()=>{uploadknowledgeLoading=false}"
          :on-remove="()=>{uploadknowledgeLoading=false}"
        >
          <p class="iconfont">&#xe603;</p>
          <p class="tips">拖拽或<span>点击上传</span></p>
        </el-upload>
        <ul class="file-list">
          <li class="file-item" v-for="(item, index) in fileList" :key="index">
            <span
              class="iconfont-color"
              v-html="fileType(item.name.split('.')[1])"
            />
            <p class="name line-text--1st">{{ item.name }}</p>
            <el-button
              class="iconfont mini"
              type="custom_primary"
              circle
              @click="deleteUploadFile(index)"
            >
              &#xe620;
            </el-button>
          </li>
        </ul>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('knowledgeImport')"
        >
          取 消
        </el-button>
        <el-button
          type="custom_primary"
          size="small"
          @click="uploadTimetable"
          :disabled="btnload"
          v-loading="btnload"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 练习题导入模板 -->
    <el-dialog
      title="导入"
      :visible.sync="practiseImportDialog"
      width="560px"
      :show-close="false"
      @close="hideDialog('practiseImport')"
    >
      <div class="import-dialog">
        <p class="download-formwork">
          <el-button
            type="custom_primary"
            size="small"
            @click="downloadPractiseTemplate(1)"  
            :disabled="download1" 
            v-loading="download1" 
            >下载excel模板</el-button
          >
          <el-button
            type="custom_primary"
            size="small"
            @click="downloadPractiseTemplate(2)" 
            :disabled="download2" 
            v-loading="download2" 
            >下载模板word模板</el-button
          >
        </p>
        <el-upload
          :action="$upload.fileAction2"
          :headers="$upload.header"
          drag
          :show-file-list="practiseImportfileList.length==0" 
          :file-list="practiseImportfileList" 
          :limit="1"
          accept=".doc,.docx,.xls,.xlsx,.xlsm"
          :before-upload="uploadpractiseImportUpload"
          :on-success="uploadpractiseImportSuccess" 
          :on-error="()=>{uploadpractiseImportLoading=false}"
          :on-remove="()=>{uploadpractiseImportLoading=false}"
        >
          <p class="iconfont">&#xe603;</p>
          <p class="tips">拖拽或<span>点击上传</span></p>
        </el-upload>
        <ul class="file-list">
          <li
            class="file-item"
            v-for="(item, index) in practiseImportfileList"
            :key="index"
          >
            <span
              class="iconfont-color"
              v-html="fileType(item.name.split('.')[1])"
            />
            <p class="name line-text--1st">{{ item.name }}</p>
            <el-button
              class="iconfont mini"
              type="custom_primary"
              circle
              @click="deletepractiseUploadFile(index)"
            >
              &#xe620;
            </el-button>
          </li>
        </ul>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('practiseImport')"
        >
          取 消
        </el-button>
        <el-button
          type="custom_primary"
          size="small"
          @click="uploadPractisetable"
          :disabled="btnload"
          v-loading="btnload"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 待引用备课数据 -- 预览 -->
    <el-drawer
      :visible.sync="previewPrepare"
      :with-header="false"
      direction="rtl"
      size="600px"
      @close="closePreviewPrepare"
    >
      <div class="preview-prepare">
        <!-- 备课信息 -->
        <ul class="plate-group preview">
          <!-- 课件 -->
          <li class="plate courseware" v-loading="quoteLoad1">
            <h4 class="box-head">
              <span class="bold">课件</span>
            </h4>
            <div class="courseware-inner">
              <div
                class="courseware--info"
                :class="{ 'no-data': !quoteCourseware.length }"
              >
                <template v-if="quoteCourseware.length">
                  <div class="info-data">
                    <p class="file-title">
                      <i class="iconfont">&#xe62f;</i>
                      <span
                        >{{ quoteCourseware[0].tetea_cou_title }}.{{
                          quoteCourseware[0].tetea_cou_type
                        }}</span
                      >
                    </p>
                    <p class="upload-time">
                      时间：{{
                        quoteCourseware[0].create_time
                          | formatTime("YYYY年M月D日")
                      }}
                    </p>
                  </div>
                </template>
                <div class="no-data--empty" v-show="!quoteCourseware.length">
                  <img src="@assets/images/no-data3.png" alt />
                  <p>暂无上传课件信息哦~</p>
                </div>
              </div>
              <div class="foot-operation" v-if="quoteCourseware.length">
                <el-button v-if="!previewDialog"
                  type="custom_success"
                  size="small"
                  @click="previewDialog = true"
                  >课件预览</el-button
                >
                <el-button v-if="previewDialog"
                  type="custom_warning"
                  size="small"
                  @click="previewDialog = false"
                  >关闭预览</el-button
                >
              </div>
            </div>
          </li>
          <!-- 知识点 -->
          <li class="plate knowledge" v-loading="quoteLoad2">
            <h4 class="box-head">
              <span class="bold">知识点</span>
            </h4>
            <div
              class="inner-box"
              :class="{ 'no-data': !quoteKnowledge.length }"
            >
              <ul class="list-wrapper" v-if="quoteKnowledge.length">
                <li
                  v-for="(item, index) in quoteKnowledge"
                  :key="item.tetea_kno_id"
                >
                  <p class="line-text--1st">
                    {{ index + 1 }}. {{ item.tetea_kno_title }}
                  </p>
                  <div class="btns">
                    <el-button
                      type="custom_success"
                      size="mini"
                      plain
                      @click="showDialog('knowledgeDetail', item)"
                      >查看</el-button
                    >
                  </div>
                </li>
              </ul>
              <div class="no-data--empty" v-show="!quoteKnowledge.length">
                <img src="@assets/images/no-data3.png" alt />
                <p>暂无相关知识点哦~</p>
              </div>
            </div>
          </li>
          <!-- 课堂练习 -->
          <li class="plate practise" v-loading="quoteLoad3">
            <h4 class="box-head">
              <span class="bold">课堂练习</span>
            </h4>
            <div
              class="inner-box"
              :class="{ 'no-data': !quotePractise.length }"
            >
              <ul class="list-wrapper">
                <li
                  v-for="(item, index) in quotePractise"
                  :key="item.tetea_pra_id"
                >
                  <div class="title">
                    {{ index + 1 }}.
                    <el-image
                      style="width: 46px; height: 32px; margin: 0 5px"
                      v-if="item.tetea_pra_title_image"
                      fit="cover"
                      :src="formatfile(item.tetea_pra_title_image)"
                      :preview-src-list="[
                        formatfile(item.tetea_pra_title_image),
                      ]"
                    />
                    <p class="line-text--1st">
                      <span
                        class="richflexcenter" 
                        style="height:50px;"
                        v-html="item.tetea_pra_title"
                      ></span>
                    </p>
                  </div>
                  <div class="btns">
                    <el-button
                      type="custom_success"
                      size="mini"
                      plain
                      @click="showDialog('practiseDetail', item.tetea_pra_id)"
                      >查看</el-button
                    >
                  </div>
                </li>
              </ul>
              <div class="no-data--empty" v-show="!quotePractise.length">
                <img src="@assets/images/no-data3.png" alt />
                <p>暂无相关课堂练习哦~</p>
              </div>
            </div>
          </li>
          <!-- 作业 -->
          <li class="plate homework" v-loading="quoteLoad4">
            <h4 class="box-head">
              <span class="bold">作业</span>
            </h4>
            <div
              class="inner-box"
              :class="{
                'no-data':
                  $isEmpty(quoteHomework) && $isEmpty(quoteSchoolHomework),
              }"
            >
              <template v-if="!$isEmpty(quoteHomework)">
                <el-tag type="info">家庭作业</el-tag>
                <div class="homework-title">
                  <p>{{ quoteHomework.tetea_hom_title }}</p>
                  <div class="btns">
                    <el-button
                      type="custom_success"
                      size="mini"
                      plain
                      @click="
                        showDialog((preparesFrom==1?'prepareHomeworkDetail':'homeworkDetail'), quoteHomework.tetea_hom_id)
                      "
                      >查看</el-button
                    >
                  </div>
                </div>
              </template>
              <template v-if="!$isEmpty(quoteSchoolHomework)">
                <el-tag type="info">校内作业</el-tag>
                <div class="homework-title">
                  <p>{{ quoteSchoolHomework.tetea_hom_title }}</p>
                  <div class="btns">
                    <el-button
                      type="custom_success"
                      size="mini"
                      plain
                      @click="
                        showDialog(
                          (preparesFrom==1?'prepareHomeworkDetail':'homeworkDetail'),
                          quoteSchoolHomework.tetea_hom_id
                        )
                      "
                      >查看</el-button
                    >
                  </div>
                </div>
              </template>
              <div
                class="no-data--empty"
                v-show="
                  $isEmpty(quoteHomework) && $isEmpty(quoteSchoolHomework)
                "
              >
                <img src="@assets/images/no-data3.png" alt />
                <p>暂无相关作业哦~</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-drawer>
    <!-- 课件预览 -->
    <el-dialog
      :title="
        (quoteCourseware.length && quoteCourseware[0].tetea_cou_title) || ''
      "
      :class="{ calc: previewPrepare }"
      :visible.sync="previewDialog"
      width="80%"
      :show-close="false"
      @close="previewDialog = false"
    >
      <div class="courseware-view" v-if="quoteCourseware.length">
        <iframe
          v-if="quoteCourseware[0].tetea_cou_type != 'pdf'"
          :src="pptCourseware"
        />
        <iframe
          v-if="quoteCourseware[0].tetea_cou_type == 'pdf'"
          :src="pdfCourseware"
        />
      </div>
    </el-dialog>
  </section>
</template>

<script>
import * as teach from "@api/teaching";
import { $getChapters,$get_school_course_list } from "@api/home";
import { $getQuesList } from "@api/homeworkExam";
import {
  formatTime,
  formatTimeStamp,
  changeEnChart,
  formatFileType,
  enChartToNum,
  formatFile,
} from "@utils";

import { mapState } from "vuex";
import customPagination from "@comp/customPagination";
import { $ocradd} from "@api/homeworkExam";
import cuttingProblem from "@comp/cuttingProblem";
var intervalId;
export default {
  name: "teaching_teach",
  components: { customPagination,cuttingProblem },
  computed: {
    ...mapState("common", ["gradeList", "classList", "questionType"]),
    /* 文件类型 */
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
    /* 上课时间 - 单元格动态类名 */
    weekTrendsClass() {
      return function (
        { sccla_sch_tim_starttime: startTime, sccla_sch_tim_endtime: endTime },
        val,
        key
      ) {
        let date = formatTime(val.date * 1000, "YYYY/MM/DD"); // 课程日期
        let currentTime = formatTimeStamp(new Date()); // 当前时间戳
        startTime = formatTimeStamp(`${date} ${startTime}`); // 课程开始时间戳
        endTime = formatTimeStamp(`${date} ${endTime}`); // 课程结束时间戳

        let { data } = val;
        let className = [];
        let condition = this.$isEmpty(data);
        let isEnd = currentTime > endTime;

        if (isEnd && condition) className.push("over"); // 当前时间之前的课程单元格
        if (!condition && val.state == 1) className.push("completed"); // 已结束课程
        if (!condition && val.state == 2)
          (data.is_type == 1 && className.push("oneself")) ||
            className.push("employ"); // 未结束课程内容
        if (this.currentPopover == key) className.push("show");
        return className;
      };
    },
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断是否是组合题 */
    isCompose() {
      return function (val) {
        return val == 10;
      };
    },
    /** 详情数据前缀 */
    detail_prefix() {
      return (this.previewPrepare && "tetea_") || "teles_";
    },
    pptCourseware() {
      if (!this.quoteCourseware.length) return "";
      return `https://view.officeapps.live.com/op/view.aspx?src=${formatFile(
        this.quoteCourseware[0].tetea_cou_url
      )}`;
    },
    pdfCourseware() {
      if (!this.quoteCourseware.length) return "";
      let file = formatFile(this.quoteCourseware[0].tetea_cou_url);
      let url = `pdf/web/viewer.html?file=${encodeURIComponent(file)}`;
      return url;
    },
  },
  data() {
    /* 练习题标题 */
    const teteaPraTitleValidate = (rule, value, callback) => {
      if (
        !this.practiseForm.teles_pra_title &&
        !this.practiseForm.teles_pra_title_image
      ) {
        // 未上传文件
        callback("请填写题干或者上传题干图片");
      }
      callback();
    };
    /* 文件上传校验规则 */
    const fileMustUpload = (rule, value, callback) => {
      if (!this.uploadForm.teles_cou_url) {
        // 未上传文件
        callback(this.uploaLoading?"正在上传课件资源":"请上传课件资源");
      }
      callback();
    };
    return {
      teachData: {}, // 授课数据
      isPublicClass: 2, // 公开课状态
      classFinish: false, // 课程是否结束
      /* 选择上课时间 */
      timeDialog: false, // 选择上课时间弹窗显示
      teacherLessons: [], // 教师周课程表
      currentPopover: "", // 当前选中占课单元格
      changePopover: false, // 执行单元格切换
      teachSubject: [], // 教师所授科目
      lessonForm: {}, // 占课填充表单
      teachTime: {}, // 选中的上课时间
      /* 自定义周日历 */
      currentYear: "", // 当前年份
      currentMonth: "", // 当前月份
      currentDay: "", // 当前日期
      currentWeek: "", // 当前星期几
      week: ["一", "二", "三", "四", "五", "六", "日"],
      weekDays: [], // 一周的天数
      /* 筛选备课绑定章节 */
      searchDialog: false, // 显示筛选弹窗
      searchData: {}, // 筛选数据集合
      sccouData: [], // 教材
      sccouKey:"",//教材（关键字）
      chapterData: [], // 章
      chapterKey: "", // 筛选章（关键字）
      jointData: [], // 节
      jointKey: "", // 筛选节（关键字）
      /* 课件 */
      courseware: [],
      uploadDialog: false, // 显示课件上传弹窗
      uploadForm: {}, // 课件上传表单
      uploaLoading:false,//课件上传中
      coursewareList: [], // 课件列表
      coursewareLoad: false,
      //内容等待
      contentLoad: false,
      contentLoad1: false,
      contentLoad2: false,
      contentLoad3: false,
      contentLoad4: false,
      uploadRules: {
        teles_cou_title: [
          { required: true, message: "请填写课件名称", trigger: "blur" },
        ],
        teles_cou_remark: [
          { required: true, message: "请填写课件描述", trigger: "blur" },
        ],
        teles_cou_url: [{ validator: fileMustUpload, trigger: "change" }],
      },
      /* 知识点 */

      knowledgeImportDialog: false, // 知识点导入上传
      uploadknowledgeLoading:false,//知识点文件上传中
      knowledgeImportForm: {}, // 知识点表单
      knowledgeList: [], //导入list
      btnload: false,
      fileList: [], //知识点导入列表

      teachKnowledge: [],
      knowledgeDialog: false, // 显示知识点新增、编辑弹窗
      knowledgeDetailDialog: false, // 显示知识点详情弹窗
      knowledgeForm: {}, // 知识点表单
      knowledgeDetail: {}, // 知识点详情
      knowledgeLoad: false,
      knowledgeRules: {
        teles_kno_title: [
          { required: true, message: "请填写知识点标题", trigger: "blur" },
        ],
        teles_kno_content: [
          { required: true, message: "请填写知识点内容", trigger: "blur" },
        ],
      },
      /* 课堂作业 */
      practiseImportDialog: false, //课堂练习导入上传
      uploadpractiseImportLoading:false,//课堂练习上传中
      showCutting:false,//试卷识别弹窗
      loadingall:false,//等待弹窗
      practiseImportForm: {}, // 知识点表单
      knowledgeList: [], //导入list

      practiseLoad: false,
      teachPractise: [],
      practiseDialog: false, // 显示课堂练习新增、编辑弹窗
      practiseDetailDialog: false, // 显示课堂练习详情弹窗
      practiseForm: {
        teles_pra_ismakeup: 20,
        teles_pra_title: "",
        option_arr: [],
      },
      practiseRules: {
        teles_pra_title: [
          { validator: teteaPraTitleValidate, trigger: "change" },
        ],
      },

      practiseDetail: {
        option_arr: [
          {
            teles_kno_title: "",
            sure_answer: [""],
            teles_pra_analysis: "",
          },
        ],
      }, // 课堂练习详情
      practiseDetailLoad: false,

      /* 作业 */
      teachHomework: {},
      hom_id: 0,
      prepareSchoolHomework: {},
      homeworkDialog: false, // 显示作业新增、编辑弹窗
      homeworkDetailDialog: false, // 显示作业详情弹窗
      prepareHomeworkDetailDialog: false, // 引用备课显示作业详情弹窗
      homeworkForm: {}, // 作业表单
      homeworkDetail: {}, // 作业详情
      homeworkQues: [], // 作业内选中题目
      selectQues: [], // 可选题目列表
      homeworkLoad: false,
      /* 可选题目分页参数 */
      selectQuesPage: {
        index: 1,
        total: 0,
      },
      selectQuesKey: "", // 可选题目筛选关键字
      homeworkRules: {
        teles_hom_title: [
          // { required: true, message: "请填写作业标题", trigger: "blur" },
        ],
        teles_hom_remark: [
          { required: true, message: "请填写作业描述", trigger: "change" },
        ],
      },
      /* 课程回放 */
      backPlayDialog: false,
      backPlayList: [], // 课程回放列表
      //手写板在线数据
      submitList: {},
      //课程信息统计
      boardList: {},
      //上课按钮等待
      attendLoad: false,
      //上传的文件列表
      practiseImportfileList: [],
      //新建切换题目小题展示
      atPresentQues: {},
      /* ----------------------------- 以下是引用备课数据相关参数变量 ----------------------------- */
      quote_doing: false, // 引用操作是否正在执行
      sccou_cha_id: null, // 章节id
      preparesFrom: 1, // 备课数据来源：1、学校 2、平台
      preparesData: [], // 待引用备课数据列表
      preparesPage: {
        pageindex: 1,
        pagesize: 12,
        total: 0,
      },
      previewPrepare: false, // 预览窗口是否打开
      quoteCourseware: [], // 课件信息
      quoteKnowledge: [], // 知识点列表
      quotePractise: [], // 课堂练习
      quoteHomework: {}, // 家庭作业
      quoteSchoolHomework: {}, // 校内作业
      quoteLoad: false,
      quoteLoad1: false,
      quoteLoad2: false,
      quoteLoad3: false,
      quoteLoad4: false,
      previewDialog: false, // 预览课件弹窗

      // 课堂练习导入切题
      download1: false,
      download2: false,
      //批量加入
      allCheck: false,
      //部分选中
      someCheck: false,
      //是否显示整个页面
      showQuestionDoc: false,
      submitQuestionDocload:false,
      // 导入数量
      succNum:0,
      //导入的题库数据
      questionDoc:[],
      sccou_id:0,
      downloadLessonskoading:true,
    };
  },
  created() {
    let teclaId = sessionStorage.getItem("teclaId");
    sessionStorage.removeItem("teclaId");
    this.initWeek();
    this.$nextTick(() => {
      if (teclaId) {
        this.getTeachData({ tecla_id: teclaId }, true);
      } else {
        if (this.$isEmpty(this.teachData)) {
          this.timeDialog = true;
        } else {
          this.initData();
        }
      }
    });
  },
  mounted() {
    document.addEventListener("click", (e) => {
      let el = e.target.className.split(" ");
      if (el[0] != "vacancy" && this.changePopover) {
        this.changePopover = false;
        this.hidePopover();
      }
    });
  },
  watch: {},
  methods: {
    /** 获取页面数据 */
    initData() {
      // 设置5秒后下载按钮才生效
      setTimeout(() => {
        this.downloadLessonskoading=false
      }, 5000);
      this.getCourseware();
      this.getKnowledge();
      this.getPractise();
      this.getHomework();
      this.changePreparesFrom(this.preparesFrom);
    },
    /** 设置公开课 */
    async setClassPublic(e) {
      if (!this.teachData.sccou_cha_title) {
        this.isPublicClass = (e == 1 && 2) || 1;
        return this.$message.warning("请设置此堂课程内容");
      } else {
        let res = await teach.$setClassOpen(
          this.teachData.tecla_id,
          this.isPublicClass
        );
        if (res) this.getTeachData({ tecla_id: this.teachData.tecla_id });
        else this.isPublicClass = (e == 1 && 2) || 1;
      }
    },
    /**
     * 获取学生连接
     * (手写板接入后联调)
     */
    async getStudents() {},

    /** 上课 */
    async startTeach() {
      if (!this.teachData.sccou_cha_title)
        return this.$message.warning("请设置此堂课程内容");
      let startTime = `${formatTime(
        this.teachData.tecla_time * 1000,
        "YYYY-MM-DD"
      )} ${this.teachData.sccla_sch_tim_starttime}`;
      startTime = new Date(startTime).getTime() - 10 * 60 * 1000;
      if (new Date().getTime() < startTime) {
        // return this.$message.warning("还未到上课时间");
      }
      this.attendLoad = true;
      let res = await teach.$attendClass(this.teachData.tecla_id);
      this.false = true;
      this.attendLoad = false;
      if (res) {
        // 第一次打开手写板
        if (!sessionStorage.getItem("openServer")) {
          sessionStorage.setItem("openServer", true);
          //打开手写板
          let url =
            "RunZhiYuanTongApServer://" +
            this.teachData.sccla_id +
            ",lisi,wusong";
          window.open(url, "_blank");
        }
        this.$router.push({
          name: "TEACHING_ATTENDCLASS",
          params: {
            id: this.teachData.tecla_id,
            sccla_id: this.teachData.sccla_id,
          },
        });
      }
    },
    /**
     * 获取授课数据
     * @param {object} params
     * @param {boolean} init 是否刷新备课数据
     */
    async getTeachData(params, init) {
      this.contentLoad = true;
      let { data: res } = await teach.$getTeachId(params);
      this.contentLoad = false;
      this.teachData = { ...res };
      this.isPublicClass = res.is_open;
      this.sccou_cha_id = res.sccou_cha_id;
      this.sccou_id = res.sccou_id;
      if (init) {
        this.get_school_course_list()
        this.initData();
      }
      if (res.tecla_status != 30) {
        //获取手写板学生人数
        this.getSubmitList();
      } else {
        //获取课程信息统计
        this.getBboardList();
      }
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 周数据格式化
     * @param {Date} time 当前时间
     * @param {String} type 切换类型
     */
    initWeek(time, type) {
      let date = (time && new Date(time)) || new Date();
      let currentDay = date.getDate(); // 今日日期 几号
      let currentYear = date.getFullYear(); // 当前年份
      let currentMonth = date.getMonth() + 1; // 当前月份
      let currentWeek = (date.getDay() == 0 && 7) || date.getDay(); // 星期几 [1,2,3...6,0]
      /* 若切换时currentWeek不是星期一，重置今日日期与星期 */
      if (currentWeek > 1 && time && type == "month") {
        currentDay = currentDay + (7 - currentWeek) + 1;
        currentWeek = 1;
      }
      let str = formatTime(`${currentYear}-${currentMonth}-${currentDay}`); // 今日日期 年-月-日
      let weekDays = [];
      /* 今天是周日，放在第一行第7个位置，前面6个 这里默认显示一周，如果需要显示一个月，则第二个循环为 i<= 35- currentWeek */
      for (let i = currentWeek - 1; i >= 0; i -= 1) {
        let d = new Date(str.replace(/\-/g, "/"));
        d.setDate(d.getDate() - i);
        weekDays.push(d);
      }
      for (let i = 1; i <= 7 - currentWeek; i += 1) {
        let d = new Date(str.replace(/\-/g, "/"));
        d.setDate(d.getDate() + i);
        weekDays.push(d);
      }
      this.currentDay = currentDay;
      this.currentYear = currentYear;
      this.currentMonth = currentMonth;
      this.currentWeek = currentWeek;
      this.weekDays = weekDays;
      this.getTimetable();
    },
    /**
     * 选择上课时间
     * ---- 周切换
     * @param {Number} type 切换方向
     */
    handleChangeWeek(type) {
      let date;
      if (type === 1) {
        date = this.weekDays[6];
        date.setDate(date.getDate() + 7);
      } else {
        date = this.weekDays[0];
        date.setDate(date.getDate() - 7);
      }
      this.initWeek(date);
    },
    /**
     * 选择上课时间
     * ---- 月份切换
     * @param {Number} year 当前年份
     * @param {Number} month 当前月份
     * @param {Number} type 切换方向
     */
    handleChangeMonth(type) {
      let year = this.currentYear;
      let month = this.currentMonth;
      let date = new Date(formatTime(`${year}-${month}-1`));
      if (type == 1) {
        date.setDate(35);
      } else {
        date.setDate(0);
      }
      this.initWeek(
        formatTime(`${date.getFullYear()}-${date.getMonth() + 1}-1`),
        "month"
      );
    },
    /**
     * 选择上课时间
     * ---- 获取每周教师备课课程表
     */
    async getTimetable() {
      let time = formatTimeStamp(
        `${this.currentYear}/${this.currentMonth}/${this.currentDay}`
      );
      let { data } = await teach.$getTimetable(time);
      data = data && data.length && data[0];
      this.teacherLessons = data;
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 打开占课弹出框
     * @param {string} key 指定的popover组件
     */
    showPopover(key) {
      this.changePopover = true;
      this.currentPopover = key;
      this.lessonForm = {};
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 关闭占课弹出框
     */
    hidePopover() {
      this.$refs[this.currentPopover] &&
        this.$refs[this.currentPopover][0].doClose();
      if (!this.changePopover) this.currentPopover = "";
      this.lessonForm = {};
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 获取教师所授科目
     */
    async getTeachSubject() {
      delete this.lessonForm.sysub_id;
      let id = this.lessonForm.grade_class.split(",")[1];
      let { data } = await teach.$getTeachSubject(id);
      this.teachSubject = data;
    },
    /**
     * 选择上课时间
     * ---- 课程表内容选中
     * @param {object} col 课程数据
     * @param {object} row 行数据
     */
    async chooseTeachTime(col, row) {
      let { data } = col;
      let lesson = this.lessonForm;
      let params = {
        sccla_sch_tim_id: row.sccla_sch_tim_id,
        date: col.date,
      };
      this.lessonForm = {};
      this.changePopover = false;
      this.hidePopover();
      if (!data.tecla_id && col.state == 1 && col.tecla_status != 30)
        return this.$message.error(
          "该堂课程未进行备课且未进行授课，无法查看课程信息"
        );
      this.hideDialog("time");
      if (this.$isEmpty(data)) {
        params = {
          ...params,
          tecla_grade: lesson.grade_class.split(",")[0],
          sccla_id: lesson.grade_class.split(",")[1],
          sysub_id: lesson.sysub_id,
        };
      } else if (!data.tecla_id) {
        params = {
          ...params,
          tecla_grade: data.sccla_sch_grade,
          sccla_id: data.sccla_id,
          sysub_id: data.sysub_id,
        };
      } else if (data.tecla_id) {
        params = { tecla_id: data.tecla_id };
      }
      let res;
      if (col.state == 1 && col.tecla_status != 30)
        res = await this.overClass(data.tecla_id);
      if (col.state != 1 || (col.state == 1 && res))
        this.getTeachData(params, true);
    },
    /**
     * 选择上课时间
     * ---- 清除占课内容
     * @param {number} id 课程id
     */
    clearTeachTime(id) {
      this.$msgbox({
        title: "提示",
        message: "确认清除当前课程内容？",
        type: "info",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await teach.$clearTeachTime(id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "当前课程内容已清除！",
            });
            this.getTimetable();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /** 下课 */
    async overClass(id) {
      return await teach.$overClass(id);
    },
  
  /**
   * 筛选
   * ---- 获取学校教材
   * @param {int}  grade 年级  sysub_id 科目  chapter_word 章节关键字 is_trees 是否返回树状1是 2否
   */
  async get_school_course_list() {
    delete this.searchData.sccou; // 清除选中的节id
    let params = {
      chapter_word:this.sccouKey,
      grade:this.teachData.tecla_grade,
      sysub_id: this.teachData.sysub_id,
    };
    let { data } = await $get_school_course_list(params);
    this.sccouData = data
    
    delete this.searchData.chaper;
    delete this.searchData.joint;
    this.chapterData = [];
    this.jointData = [];
    this.chapterKey = "";
    this.jointKey = "";
    this.$forceUpdate();
  },
    /**
     * 筛选
     * ---- 获取章节数据
     * @param {int} type 数据类型 1：章   2：节
     */
    async getChapters(type) {
      let { chaper, sccou } = this.searchData;
      delete this.searchData.joint; // 清除选中的节id
      if (this.$isEmpty(chaper) && this.$isEmpty(sccou) && type == 2) return;
      let params = {
        chapter_word: (type == 1 && this.chapterKey) || this.jointKey,
        grade: this.teachData.tecla_grade,
        sysub_id: this.teachData.sysub_id,
        sccou_id:sccou.id
      };
      /* 若选中章触发，传递章id获取节数据 */
      if (chaper && type != 1) params.sccou_cha_pid = chaper.id;
      let { data } = await $getChapters(params);
      /* 章 */
      if (type == 1) {
        this.chapterData = data[0];
        /* 章数据更新，清除选中章及节数据 */
        delete this.searchData.chaper;
        this.jointData = [];
        this.jointKey = "";
      } else {
        /* 节 */
        this.jointData = data[0];
      }
      this.$forceUpdate();
    },
    /**
     * 筛选
     * ---- 筛选节点选中
     * @param {string} el 筛选数据key
     * @param {number} val 筛选数据value
     */
    chooseSearchKey(el, id, name) {
      if (this.searchData[el] && this.searchData[el].id == id) return;
      this.searchData[el] = { id, name };
      /* 根据选中数据类型，清空对应子级数据 */
      switch (el) {
        case "sccou"://选中教材清理数据
          delete this.searchData.chaper;
          delete this.searchData.joint;
          this.chapterData = [];
          this.jointData = [];
          this.chapterKey = "";
          this.jointKey = "";
          break;
        case "chaper":
          delete this.searchData.joint;
          this.jointData = [];
          this.jointKey = "";
          break;
      }
      /* 选中章时，获取章节数据 */
      if (el == "sccou"){
        this.getChapters(1);
      }
      /* 选中章时，获取章节数据 */
      if (this.searchData.sccou && el == "chaper"){
        this.getChapters(2);
      }
      this.$forceUpdate();
    },
    /**
     * 筛选
     * ---- 设置授课章节
     */
    async setTeachChapter() {
      let { chaper, joint } = this.searchData;
      let teachData = this.teachData;
      let id = (joint && joint.id) || chaper.id;
      this.hideDialog("search");

      this.contentLoad = true;
      let res = await teach.$setTeachChapter(id, teachData.tecla_id);
      this.contentLoad = false;
      if (res) this.getTeachData({ tecla_id: teachData.tecla_id }, true);
    },
    /**
     * 课件
     * ---- 获取课件资源
     */
    async getCourseware() {
      this.contentLoad1 = true;
      let { data } = await teach.$getTeachCourseware(this.teachData.tecla_id);
      this.contentLoad1 = false;
      this.courseware = data;
      this.$forceUpdate();
    },
    /**
     * 课件
     * ---- 新增备课课件
     */
    addCourseware() {
      this.$refs.uploadForm.validate(async (valid) => {
        if (valid) {
          this.coursewareLoad = true;
          let params = {
            ...this.uploadForm,
            tecla_id: this.teachData.tecla_id,
          };
          this.hideDialog("upload");
          let res = await teach.$uploadTeachCourseware(params);
          this.coursewareLoad = false;
          if (res) this.getCourseware();
        }
      });
    },
    /** 删除备课课件 */
    deleteCourseware() {
      this.$msgbox({
        title: "删除",
        message: "确认删除此备课课件？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await teach.$delTeachCourseware(
            this.courseware[0].teles_cou_id
          );
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "课件已清除！",
            });
            this.getCourseware();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    // 上传课件
    uploadBefore(file){
      if((file.size / 1024 / 1024) > 200){
        this.$message.warning("上传文件大小不能超过 200MB!");
        return false
      }else if(this.uploaLoading){
        this.$message.warning("文件上传中，请稍后");
        return false
      }else if(this.coursewareList.length>0){
        this.$message.warning("超出上传个数，请删除后再上传！");
        return false
      }
      this.uploaLoading=true;
      this.$beforFileUpload(file, ['ppt','pptx','pdf','doc','docx','PPT','PPTX','PDF','DOC','DOCX']);
    },
    /**
     * 课件
     * ---- 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.uploadForm.teles_cou_url = data.address;
      this.uploadForm.teles_cou_type = data.file_type;
      let courseware = { name, type: data.file_type };
      this.coursewareList = [courseware];
      this.$refs.uploadForm.validateField("teles_cou_url");
      this.uploaLoading=false
    },
    /**
     * 课件
     * ---- 移出上传课件资源
     */
    removeCoursewareFile() {
      delete this.uploadForm.teles_cou_url;
      delete this.uploadForm.teles_cou_type;
      this.uploaLoading=false
      this.coursewareList = [];
    },
    /**
     * 课堂回放
     * ---- 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadBackPlaySuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      let backPlay = { name, type: data.file_type, address: data.address };
      this.backPlayList = [backPlay];
    },
    /**
     * 课堂回放
     * ---- 移出上传视频资源
     */
    removeBackPlayFile() {
      this.backPlayList = [];
    },
    /**
     * 课堂回放
     * ---- 上传课堂回放视频
     */
    async uploadBackPlay() {
      let res = await teach.$uploadClassReplay(
        this.teachData.tecla_id,
        this.backPlayList[0].address
      );
      if (res) {
        this.hideDialog("backPlay");
        this.getCourseware();
      }
    },
    /**
     * 知识点
     * ---- 获取备课知识点
     */
    async getKnowledge() {
      this.contentLoad2 = true;
      let { data } = await teach.$getTeachKnowledge(this.teachData.tecla_id);
      this.contentLoad2 = false;
      this.teachKnowledge = data;
      this.$forceUpdate();
    },
    /**
     * 知识点
     * ---- 新增备课知识点
     */
    addKnowledge() {
      this.$refs.knowledgeForm.validate(async (valid) => {
        if (valid) {
          this.knowledgeLoad = true;
          let params = { ...this.knowledgeForm };
          this.hideDialog("knowledge");
          if (params.teles_kno_id) return this.editKnowledge(params);
          params.tecla_id = this.teachData.tecla_id;
          let res = await teach.$addTeachKnowledge(params);
          this.knowledgeLoad = false;
          if (res) {
            this.hideDialog("knowledge");
            this.getKnowledge();
          }
        }
      });
    },
    /**
     * 知识点
     * ---- 编辑备课知识点
     * @param {object} params 表单数据
     */
    async editKnowledge(params) {
      let res = await teach.$editTeachKnowledge(params);
      this.knowledgeLoad = false;
      if (res) {
        this.hideDialog("knowledge");
        this.getKnowledge();
        this.$message({
          type: "success",
          duration: 1500,
          message: "知识点编辑保存成功",
        });
      }
    },
    /**
     * 知识点
     * ---- 删除备课知识点
     * @param {number} id 知识点id
     */
    delKnowledge(id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除此条知识点？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await teach.$delTeachKnowledge(id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "知识点已删除！",
            });
            this.getKnowledge();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /** 下载知识点模板 */
    async downloadTemplate() {
      let { data } = await teach.$downloadTemplate();
      this.$fileDownload(data.full_file_url);
    },
    // 上传知识点导入
    uploadknowledgeImportupload(file){
      if(this.uploadknowledgeLoading){
        this.$message.warning("文件上传中，请稍后");
        return false
      }else if(this.fileList.length>0){
        this.$message.warning("超出上传个数，请删除后再上传！");
        return false
      }
      this.uploadknowledgeLoading=true;
      this.$beforFileUpload(file, ['xls', 'xlsx', 'xlsm','XLS', 'XLSX', 'XLSM']);
    },
    /**上传成功 */
    uploadknowledgeImportSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.knowledgeImportForm.url = data.address;
      let courseware = { name, type: data.file_type };
      this.fileList = [courseware];
      this.uploadknowledgeLoading=false
    },
    /** 导入知识点模板 */
    async uploadTimetable() {
      if (this.$isEmpty(this.knowledgeImportForm.url))
        return this.$message.warning(this.uploadknowledgeLoading?"正在上传文件，请稍后":"请上传需要导入的文件");
      this.btnload = true;
      let params = {
        tecla_id: this.teachData.tecla_id,
        inportfile: this.knowledgeImportForm.url,
      };
      this.fileList = [];
      let res = await teach.$importLessonKnowledge(params);
      this.btnload = false;
      if (res) {
        this.hideDialog("knowledgeImport");
        this.getKnowledge();
      }
    },
    /** 删除上传文件 */
    deleteUploadFile(index) {
      this.fileList.splice(index, 1);
      this.uploadknowledgeLoading=false
    },
    /**
     * 课堂练习
     * ---- 获取课堂练习数据
     */
    async getPractise() {
      this.contentLoad3 = true;
      let { data } = await teach.$getTeachPractiseList(this.teachData.tecla_id);
      this.contentLoad3 = false;
      this.teachPractise = data;
      this.$forceUpdate();
    },
    /** 下载练习题导入模板 */
    async downloadPractiseTemplate(type) {
      if(type==1){this.download1=true}else{this.download2=true}
      let { data } = await teach.$downloadPractiseTemplate({type:type});
      if(type==1){this.download1=false}else{this.download2=false}
      this.$fileDownload(data.full_file_url);
    },
    // 练习题上传文件
    uploadpractiseImportUpload(file){
      if(this.uploadpractiseImportLoading){
        this.$message.warning("文件上传中，请稍后");
        return false
      }else if(this.practiseImportfileList.length>0){
        this.$message.warning("超出上传个数，请删除后再上传！");
        return false
      }
      this.uploadpractiseImportLoading=true;
      this.$beforFileUpload(file, ['doc', 'docx', 'xls', 'xlsx', 'xlsm','DOC', 'DOCX', 'XLS', 'XLSX', 'XLSM']);
    },
    /**
     * 练习题批量上传
     * ---- 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadpractiseImportSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.practiseImportForm.url = data.address;
      let courseware = { name, type: data.file_type };
      this.practiseImportfileList = [courseware];
      this.uploadpractiseImportLoading=false
      this.$forceUpdate();
    },
    /** 删除练习题上传文件 */
    deletepractiseUploadFile(index) {
      this.practiseImportfileList.splice(index, 1);
      this.uploadpractiseImportLoading=false
    },
    /** 导入练习题模板 */
    async uploadPractisetable() {
      if (this.$isEmpty(this.practiseImportForm.url))
        return this.$message.warning(this.uploadpractiseImportLoading?"正在上传文件，请稍后":"请上传需要导入的文件");
      this.btnload = true;
      let params = {
        tecla_id: this.teachData.tecla_id,
        inportfile: this.practiseImportForm.url,
        sysub_id:this.teachData.sysub_id
      };
      this.fileList = [];
      let { data } = await teach.$importLessonPractise(params);
      this.btnload = false;
      this.hideDialog("practiseImport");
      if(data.isword){
          if (data.succNum > 0) {
              this.questionDoc = data.questions
              this.succNum = data.succNum
              this.showQuestionDoc = true
          }
      }else{
          if (data.errorNum) {
              this.$message.warning(`共导入${data.allCount}道题，导入失败${data.errorNum}道`);
          } else {
              this.$message.success(`成功导入${data.allCount}道题`)
          }
          this.getPractise();
          if (data.errorNum && data.errorFileUrl) this.$fileDownload(data.errorFileUrl)
      }
    },
    /**
     * 课堂练习
     * ---- 获取课堂练习详情
     * @param {number} id 课堂练习id
     * @param {string} type 弹窗类型
     */
    async getPractiseDetail(id, type) {
      this[`${type}Load`] = true;
      let res;
      if (this.previewPrepare) {
        res = await teach.$preparePractiseDetail(id);
      } else {
        res = await teach.$teachPractiseDetail(id);
      }
      let { data } = res;
      this[`${type}Load`] = false;
      let option_arr = [];
      data.option_arr.forEach((que) => {
        let que_item = {
          answer: [],
          syque_typ_id: que.syque_typ_id,
          question_item: [...que.question_item],
        };
        if (data[`${this.detail_prefix}pra_ismakeup`] == 10) {
          if (que[`${this.detail_prefix}pra_title`])
            que_item[`${this.detail_prefix}children_title`] =
              que[`${this.detail_prefix}pra_title`];
          if (que[`${this.detail_prefix}pra_title_image`])
            que_item[`${this.detail_prefix}children_image`] =
              que[`${this.detail_prefix}pra_title_image`];
        }
        if (que[`${this.detail_prefix}kno_id`]) {
          que_item[`${this.detail_prefix}kno_id`] =
            que[`${this.detail_prefix}kno_id`];
        }
        if (que[`${this.detail_prefix}pra_analysis`])
          que_item[`${this.detail_prefix}pra_analysis`] =
            que[`${this.detail_prefix}pra_analysis`];
        que.sure_answer.map((item) => {
          que_item.answer.push(enChartToNum(item));
        });
        que_item.answer = (que_item.answer.length > 1 && que_item.answer) || que_item.answer[0];
        option_arr.push(que_item);
      });
      let formData = {
        option_arr: [...option_arr],
      };
      formData[`${this.detail_prefix}pra_id`] =
        data[`${this.detail_prefix}pra_id`];
      formData[`${this.detail_prefix}pra_title`] =
        data[`${this.detail_prefix}pra_title`];
      formData[`${this.detail_prefix}pra_ismakeup`] =
        data[`${this.detail_prefix}pra_ismakeup`];

      if (data[`${this.detail_prefix}pra_title_image`])
        formData[`${this.detail_prefix}pra_title_image`] =
          data[`${this.detail_prefix}pra_title_image`];

      if (type == "practise") {
        this.practiseForm = { ...formData };
      } else if (type == "practiseDetail") {
        this.practiseDetail = { ...data };
      }
      // this.showDialog(type);
    },
    /**
     * 课堂练习
     * ---- 新增课堂练习
     */
    addPractise() {
      this.$refs.practiseForm.validate(async (valid) => {
        if (valid) {
          this.practiseLoad = true;
          //   let params = this.$_.cloneDeep(this.practiseForm);
          this.practiseLoad = true;
          let option_arr = this.$_.cloneDeep(this.practiseForm.option_arr);
          let choiceQues = option_arr.filter((que) => {
            if (this.isChoice(que.syque_typ_id)) return que;
          });
          let answerCount = [];
          choiceQues.map((que) => {
            if (typeof que.answer == "number" || !this.$isEmpty(que.answer))
              answerCount.push(que.answer);
          });
          if (answerCount.length < choiceQues.length) {
            this.practiseLoad = false;
            return this.$message.warning("请设定题目正确答案");
          }

          /* 遍历所有题目,设置章节id */
          option_arr.map((que) => {
            let { question_item } = que;
            /* 选择题,将正确答案赋值到选项 */
            if (this.isChoice(que.syque_typ_id)) {
              question_item.map((item) => (item.teles_pra_ite_isanswer = 20));
              if (typeof que.answer == "number") {
                question_item[que.answer].teles_pra_ite_isanswer = 10;
              } else {
                que.answer.map((a) => {
                  question_item[a].teles_pra_ite_isanswer = 10;
                });
              }
            }
            if (que.chapter && !this.$isEmpty(que.chapter)) {
              que.tetea_kno_id = que.chapter[que.tetea_kno_id.length - 1];
            }
            question_item.map((opt, opt_index) => {
              opt.teque_ite_code = changeEnChart(opt_index);
            });
            delete que.answer;
            delete que.chapter;
            que = { ...que, question_item: [...question_item] };
          });
          let params = {
            ...this.practiseForm,
            sccou_id: this.qbId,
            option_arr: [...option_arr],
          };
          params.tecla_id = this.teachData.tecla_id; // 传入授课id
          if (params.teque_id) return this.editQuestion(params); // 题目id存在，执行数据编辑保存
          let res = await teach.$addTeachPractise(params);
          this.practiseLoad = false;
          if (res) {
            this.hideDialog("practise"); // 关闭表单弹窗
            this.getPractise();
            this.$message.success("练习添加成功");
          }
        }
      });
    },
    /**
     * 选中题目题型
     * @param {number} val 题型选中值
     * @param {number} que_index 题目数组下标
     */
    chooseQuesType(val, que_index) {
      let option = {
        teles_pra_ite_title: "",
        teles_pra_ite_isanswer: 20,
        teles_pra_ite_image: "",
      };
      let question_item = [];
      let { option_arr } = this.practiseForm;
      if (this.isChoice(val)) {
        let loopTime = (val == 5 && 2) || 4;
        for (let i = 0; i < loopTime; i++) {
          question_item.push({ ...option });
        }
      } else {
        question_item = [
          {
            teles_pra_ite_title: "",
            teles_pra_ite_isanswer: 10,
            teles_pra_ite_image: "",
          },
        ];
      }
      option_arr[que_index] = {
        ...option_arr[que_index],
        answer: (val == 2 && []) || "",
        question_item: [...question_item],
      };
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /** 添加组合题小题 */
    appendQues() {
      let { option_arr } = this.practiseForm;
      option_arr.push({});
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 移除组合题小题
     * @param {number} que_index 题目数组下标
     */
    removeQues(que_index) {
      let { option_arr } = this.practiseForm;
      this.$_.pullAt(option_arr, que_index);
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 课堂练习
     * ---- 编辑课堂练习
     * @param {object} params 表单数据
     */
    async editPractise(params) {
      let res = await teach.$editTeachPractise(params);
      this.practiseLoad = false;
      if (res) {
        this.hideDialog("practise"); // 关闭表单弹窗
        this.getPractise();
        this.$message({
          type: "success",
          duration: 1500,
          message: "课堂练习编辑保存成功",
        });
      }
    },
    /**
     * 课堂练习
     * ---- 删除课堂练习
     * @param {number} id 课堂练习id
     */
    delPractise(id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除此条课堂练习题吗？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await teach.$delTeachPractise(id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "课堂练习题已删除！",
            });
            this.getPractise();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /**
     * 课堂练习
     * ---- 切换题目类型
     * @param {number} val 题目类型值
     */
    checkQuestionType(val) {
      const option = {
        teles_pra_ite_title: "",
        teles_pra_ite_isanswer: 20,
        teles_pra_ite_image: "",
      };
      let answer = (val == 2 && []) || "";
      let item = [];
      let loopTime = (val == 5 && 2) || 4;
      for (let i = 0; i < loopTime; i++) {
        item.push({ ...option });
      }
      this.practiseForm = {
        ...this.practiseForm,
        answer,
        item: [...item],
      };
    },
    /**
     * 课堂练习
     * ---- 图片上传成功
     * @param {object} data 响应数据
     * @param {string} el 图片对应字段变量
     */
    uploadImgSuccess({ data, msg }, el) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      el = el.split(".");
      if (el.length == 1) this[el[0]] = data.address;
      if (el.length == 2) this[el[0]][el[1]] = data.address;
      if (el.length == 3) this[el[0]][el[1]][el[2]] = data.address;
      if (el.length == 4) this[el[0]][el[1]][el[2]][el[3]] = data.address;
      if (el.length == 5)
        this[el[0]][el[1]][el[2]][el[3]][el[4]] = data.address;
      if (el.length == 6)
        this[el[0]][el[1]][el[2]][el[3]][el[4]][el[5]] = data.address;
      this.$forceUpdate();
    },
    /**
     * 课堂练习
     * ---- 添加题目选项
     */
    appendQueOption(que_index) {
      let { option_arr } = this.practiseForm;
      let { question_item } = option_arr[que_index];
      const option = {
        teles_pra_ite_title: "",
        teles_pra_ite_isanswer: 20,
        teles_pra_ite_image: "",
      };
      question_item.push(option);
      option_arr[que_index] = {
        ...option_arr[que_index],
        question_item: [...question_item],
      };
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
    },
    /** 选中题目是否为组合题 */
    checkIsCompose(val) {
      let { teles_pra_title, teles_pra_image } = this.practiseForm;
      let form = { teles_pra_ismakeup: val, option_arr: [{}], teles_pra_title };
      if (teles_pra_image) form.teles_pra_image = teles_pra_image;
      this.practiseForm = { ...form };

      if (this.$refs.practiseForm != undefined) {
        this.$refs.practiseForm.clearValidate();
      }

      this.$forceUpdate();
    },
    /**
     * 移除题目选项
     * @param {number} que_index 题目数组下标
     * @param {number} opt_index 题目选项数组下标
     */
    removeQueOption(que_index, opt_index) {
      let { option_arr } = this.practiseForm;
      let { question_item, answer } = option_arr[que_index];
      if (answer + "") {
        if (typeof answer == "number") {
          if (answer == opt_index) answer = "";
          if (answer > opt_index) answer = (answer - 1 < 0 && "") || answer - 1;
        } else {
          if (answer.indexOf(opt_index) != -1) {
            let a_index = answer.indexOf(opt_index);
            this.$_.pullAt(answer, a_index);
          }
          let newAnswer = [];
          answer.map((item) => {
            if (item > opt_index) newAnswer.push(item - 1);
            else newAnswer.push(item);
          });
          answer = [...newAnswer];
        }
      }
      this.$_.pullAt(question_item, opt_index);
      option_arr[que_index] = {
        ...option_arr[que_index],
        question_item: [...question_item],
        answer,
      };
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 获取备课作业信息
     */
    async getHomework() {
      this.contentLoad4 = true;
      let { data } = await teach.$getTeachHomework(this.teachData.tecla_id);
      this.contentLoad4 = false;
      this.teachHomework = data["prepareHomework"] || {};
      this.prepareSchoolHomework = data["prepareSchoolHomework"] || {};
      this.$forceUpdate();
    },
    /**
     * 手写板
     * ---- 获取手写板在线数据
     */
    async getSubmitList() {
      let res = await teach.$getSubmitList(this.teachData.sccla_id, 0);
      this.submitList = res.data || {};
    },
    /**
     *
     * ---- 课程统计信息
     */
    async getBboardList() {
      let res = await teach.$getBboardList(this.teachData.tecla_id);
      this.boardList = res.data || {};
    },

    /**
     * 作业
     * ---- 获取作业详情
     * @param {string} type 弹窗类型
     */
    async getHomeworkDetail(type) {
      let res;
      if (this.previewPrepare) {
        res = await teach.$prepareHomeworkDetail(this.hom_id);
      } else {
        res = await teach.$teachHomeworkDetail(this.hom_id);
      }
      let { data } = res;
      if (type == "homework") {
        let form = {},
          formKey = [
            `${this.detail_prefix}hom_id`,
            `${this.detail_prefix}hom_title`,
            `${this.detail_prefix}hom_remark`,
            `${this.detail_prefix}hom_type`,
          ];
        formKey.map((item) => (form[item] = data[item]));
        this.homeworkForm = {
          ...form,
        };
        this.homeworkQues = [...data.question];
      } else {
        this.homeworkDetail = { ...data };
      }
    },
    /**
     * 作业
     * ---- 获取可选题目列表
     */
    async getQues() {
      if(!this.sccou_id){
        this.$message.warning("请先选择章节");
      }
      let params = {
        syque_typ_id: this.homeworkForm.syque_typ_id,
        query_word: this.selectQuesKey,
        pageindex: this.selectQuesPage.index,
        sccou_id: this.sccou_id,
      };
      delete this.homeworkForm.que_id;
      let { data: res } = await $getQuesList(1, params);
      this.selectQues = [...this.selectQues, ...res.data];
      this.selectQuesPage.total = res.allcount;
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 筛选可选题目列表
     */
    searchQues() {
      this.selectQues = [];
      this.selectQuesKey = "";
      this.selectQuesPage.index = 1;
      delete this.homeworkForm.que_id;
      this.getQues();
    },
    /**
     * 作业
     * ---- 根据关键字筛选可选题目
     * @param {string} query 可选题目关键字
     */
    filterQues(query) {
      this.selectQuesKey = query;
      this.selectQues = [];
      this.selectQuesPage.index = 1;
      this.getQues();
    },
    /**
     * 作业
     * ---- 更多可选题目数据加载
     */
    loadMoreQues() {
      if (this.selectQues.length == this.selectQuesPage.total) return;
      this.selectQuesPage.index++;
      this.getQues();
    },
    /**
     * 作业
     * ---- 添加选中题目
     */
    appendHomeworkQue() {
      if (!this.homeworkForm.syque_typ_id || !this.homeworkForm.que_id) return;
      let { que_id } = this.homeworkForm;
      delete this.homeworkForm.syque_typ_id;
      delete this.homeworkForm.que_id;
      if (!this.$_.find(this.homeworkQues, ["teque_id", que_id])) {
        let que = this.$_.find(this.selectQues, ["teque_id", que_id]);
        this.homeworkQues.push(que);
      } else {
        this.$message.warning("选中项已存在作业题目列表中");
      }
      this.selectQues = [];
      this.selectQuesKey = "";
      this.selectQuesPage.index = 1;
      this.atPresentQues = {};
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 移除选中题目
     * @param {number} index 题目下标
     */
    removeHomeworkQue(index) {
      this.$_.pullAt(this.homeworkQues, index);
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 调整题目顺序
     * @param {string} type 调整类型
     * @param {number} index 题目下标
     */
    changeSort(type, index) {
      const dot_1 = this.homeworkQues[index];
      let dot_2;
      /* 下移 */
      if (type == "d") {
        dot_2 = this.homeworkQues[index + 1];
        this.homeworkQues[index + 1] = { ...dot_1 };
      }
      /* 上移 */
      if (type == "u") {
        dot_2 = this.homeworkQues[index - 1];
        this.homeworkQues[index - 1] = { ...dot_1 };
      }
      this.homeworkQues[index] = { ...dot_2 };
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 添加作业
     */
    addHomework() {
      this.$refs.homeworkForm.validate(async (valid) => {
        if (valid) {
          this.homeworkLoad = true;
          let params = this.$_.cloneDeep(this.homeworkForm);
          params.tecla_id = this.teachData.tecla_id; // 传入授课id
          params.question = [];
          let homeworkQues = this.$_.cloneDeep(this.homeworkQues);
          homeworkQues.map((item) => {
            let que = {
              que_id: item.teque_id,
              teles_hom_ite_ismakeup: item.teque_ismakeup,
            };
            params.question.push(que);
          });
          if (params.teles_hom_id) return this.editHomework(params); // 存在作业id，执行作业编辑
          let res = await teach.$addTeachHomework(params);
          this.homeworkLoad = false;
          if (res) {
            this.hideDialog("homework"); // 关闭表单弹窗
            this.getHomework();
          }
        }
      });
    },
    /**
     * 作业
     * ---- 编辑作业
     * @param {object} params 表单数据
     */
    async editHomework(params) {
      let res = await teach.$editTeachHomework(params);
      this.homeworkLoad = false;
      if (res) {
        this.hideDialog("homework"); // 关闭表单弹窗
        this.getHomework();
        this.$message({
          type: "success",
          duration: 1500,
          message: "作业编辑保存成功",
        });
      }
    },
    /**
     * 作业
     * ---- 删除作业
     */
    delHomework(hom_id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除作业？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await teach.$delTeachHomework(hom_id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "作业已删除！",
            });
            this.getHomework();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /**
     * 开启弹窗
     * @param {string} type 弹窗类型
     */
    showDialog(type, ...params) {
      let data = {};
      if (this.previewPrepare) {
        this.hideDialog("knowledgeDetail");
        this.hideDialog("practiseDetail");
        this.hideDialog("homeworkDetail");
        this.hideDialog("prepareHomeworkDetail");
      }
      if(this.previewDialog) this.previewDialog = false;
      if (this[`${type}Form`]) {
        this.resetForm(`${type}Form`);
      }
      if (params.length) {
        if (type == "knowledge" || type == "knowledgeDetail") {
          data = { ...params[0] };
        }
        if (type == "practise" || type == "practiseDetail") {
          this.getPractiseDetail(params[0], type);
        }
        if (type == "homework" || type == "homeworkDetail" || type == "prepareHomeworkDetail") {
          this.hom_id = params;
          this.getHomeworkDetail(type);
        }
      }
      switch (type) {
        case "time":
          this.getTimetable();
          break;
        case "knowledge":
          this.knowledgeForm = { ...data };
          break;
        case "knowledgeDetail":
          this.hom_id = params;
          this.knowledgeDetail = { ...data };
        case "knowledgeImport":
          this.knowledgeImportForm.url = "";
          this.knowledgeDetail = { ...data };
        case "practiseImport":
          this.practiseImportForm.url = "";
          this.practiseImportfileList = [];
        case "practise":
          this.practiseForm = {
            ...this.practiseForm,
            item: [{}, {}],
          };
          this.checkIsCompose(20);
          break;
        // case "practiseDetail":
        //   break;
        default:
          break;
      }
      this[`${type}Dialog`] = true;
    },
    /**
     * 关闭弹窗
     * @param {string} type 弹窗类型
     */
    hideDialog(type) {
      this[`${type}Dialog`] = false;
      if (type == "time" && this.$isEmpty(this.teachData)) {
        this.teachData = {};
      }
      if (type == "backPlay") this.backPlayList = [];
      if (this[`${type}Form`]) {
        switch (type) {
          case "upload":
            this.coursewareList = [];
            break;
          case "homework":
            this.homeworkQues = [];
            this.selectQues = [];
            break;
          // case "practise":
          // this.resetForm(`practiseForm`);
          // this[`practiseForm`] = {};

          //   break;
          default:
            break;
        }
        this.$nextTick(() => {
          this.resetForm(`${type}Form`);
          this[`${type}Form`] = {};
          if (type == "practise") {
            this.practiseForm = {
              tetea_pra_ismakeup: 20,
              option_arr: [{}],
            };
          }else if (type == "upload") {
            this.uploadForm={
              tetea_is_share_type: 0
            }
            this.uploaLoading=false
          }else if (type == "knowledgeImport") {
            this.uploadknowledgeLoading=false
          }else if (type == "practiseImport") {
            this.uploadpractiseImportLoading=false
          }
        });
      }
      if (this[type]) {
        if (type == "practiseDetail") {
          this[type] = {
            option_arr: [
              {
                teles_kno_title: "",
                sure_answer: [""],
                teles_pra_analysis: "",
              },
            ],
          };
        } else {
          this[type] = {};
        }
      }
    },
    /** 重置表单 */
    resetForm(ref) {
      if (this.$refs[ref]) this.$refs[ref].clearValidate();
    },

    // 切换题
    queSelect(e) {
      if (e.id) {
        this.atPresentQues = e.item;
      } else {
        this.atPresentQues = {};
      }
      // this.atPresentQues = {};
      // this.selectQues.forEach((item) => {
      //   if (item.teque_id == e) {
      //     this.atPresentQues = item;
      //   }
      // });
    },

    /* ------------------------------ 以下是添加的引用备课数据功能相关方法 ------------------------------ */
    /** 切换备课数据来源 */
    changePreparesFrom(val) {
      this.preparesFrom = val;
      if (!this.sccou_cha_id){
        this.preparesData=[]
        return;
      } 
      this.preparesPage = {
        ...this.preparesPage,
        pageindex: 1,
      };
      let params = {
        sccou_cha_id: this.sccou_cha_id,
        pageindex: 1,
        pagesize: this.preparesPage.pagesize,
      };
      this.quoteLoad = true;
      if (val == 1) {
        this.getSchoolPrepares(params);
      } else {
        this.getPlatformPrepares(params);
      }
    },
    /** 获取公共备课数据（学校） */
    async getSchoolPrepares(params) {
      let { data: res } = await teach.$getSchoolPrepares(params);
      this.quoteLoad = false;
      this.preparesData = [...res.data];
      this.preparesPage = {
        ...this.preparesPage,
        total: res.total,
      };
      this.$forceUpdate();
    },
    /** 获取公共备课数据（平台） */
    async getPlatformPrepares(params) {
      let { data: res } = await teach.$getPlatformPrepares(params);
      this.quoteLoad = false;
      this.preparesData = [...res.data];
      this.preparesPage = {
        ...this.preparesPage,
        total: res.total,
      };
      this.$forceUpdate();
    },
    /** 获取待引用备课数据详情 */
    async getPreparesDetails(tetea_id) {
      this.previewPrepare = true;
      this.getQuoteCourseware(tetea_id);
      this.getQuoteKnowledge(tetea_id);
      this.getQuotePractise(tetea_id);
      this.getQuoteHomework(tetea_id);
    },
    closePreviewPrepare() {
      this.previewPrepare = false;
    },
    /** 引用备课数据 */
    async quotePrepare(tetea_id) {
      this.quote_doing = true;
      let res = await teach.$quotePrepare(this.teachData.tecla_id, tetea_id);
      if (res) {
        await this.getTeachData({ tecla_id: this.teachData.tecla_id }, true);
        this.quote_doing = false;
        this.$message({
          type: "success",
          duration: 1500,
          message: "备课数据引用成功！",
        });
      } else {
        this.quote_doing = false;
      }
    },
    /** 引用备课数据 - 课件信息 */
    async getQuoteCourseware(tetea_id) {
      this.quoteLoad1 = true;
      let { data } = await teach.$getPrepareCourseware(tetea_id);
      this.quoteLoad1 = false;
      this.quoteCourseware = data;
    },
    /** 引用备课数据 - 知识点 */
    async getQuoteKnowledge(tetea_id) {
      this.quoteLoad2 = true;
      let { data } = await teach.$getPrepareKnowledge(tetea_id);
      this.quoteLoad2 = false;
      this.quoteKnowledge = data;
    },
    /** 引用备课数据 - 练习题 */
    async getQuotePractise(tetea_id) {
      this.quoteLoad3 = true;
      let { data } = await teach.$getPreparePractise(tetea_id);
      this.quoteLoad3 = false;
      this.quotePractise = data;
    },
    /** 引用备课数据 - 作业 */
    async getQuoteHomework(tetea_id) {
      this.quoteLoad4 = true;
      let { data } = await teach.$getPrepareHomework(tetea_id);
      this.quoteLoad4 = false;
      this.quoteHomework = data["prepareHomework"] || {};
      this.quoteSchoolHomework = data["prepareSchoolHomework"] || {};
    },
    /** 翻页 */
    flippingPage(val) {
      this.preparesPage.pageindex = val;
      let params = {
        sccou_cha_id: this.sccou_cha_id,
        pageindex: val,
        pagesize: this.preparesPage.pagesize,
      };
      if (this.preparesFrom == 1) {
        this.getSchoolPrepares(params);
      } else {
        this.getPlatformPrepares(params);
      }
    },
    
    //添加小题答案
    addDocQuestion(item, index) {
      if (item.question_item.length < 20) {
        let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
        item.question_item.push({ teles_pra_ite_code: letter[item.question_item.length], teles_pra_ite_isanswer: 20, teles_pra_ite_title: "" })
        this.questionDoc[index] = item
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "答案上限",
        });
      }
    },
    //删除答案
    deleteDocQuestionItem(item, index2) {
      if (item.question_item.length > 1) {
        let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
        if (item.syque_typ_id == 1 || item.syque_typ_id == 5) {
          if (item.answer == item.question_item[index2].teles_pra_ite_code) {
            item.answer = ""
          }
        }
        item.question_item.splice(index2, 1)
        item.question_item.map((item2, index) => {
          item2.teles_pra_ite_code = letter[index]
        })
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "至少保留一个答案",
        });
      }
    },
    //删除大题
    deleteQuestionDocItem(index) {
      if (this.questionDoc.length > 1) {
        this.questionDoc.splice(index, 1)
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "至少保留一个大题",
        });
      }
    },
    // 提交表单
    async questionDocsubmit() {
      let pass = true
      let error = ""
      let newsDoc = JSON.parse(JSON.stringify(this.questionDoc))

      //sccou_cha_id
      newsDoc.map((item, index) => {
        if (item.sccou_cha_id) {
          item.sccou_cha_id = item.sccou_cha_id[item.sccou_cha_id.length - 1]
        }
        if (pass && item.teles_pra_title.length < 1) {
          error = "请填写第" + (index + 1) + "题题目"
          pass = false
        }
        if (item.syque_typ_id == 1) {
          //遍历选择题里面的小题
          item.question_item.map(item2 => {
            if (item.answer == item2.teles_pra_ite_code) {
              item2.teles_pra_ite_isanswer = 10
            } else {
              item2.teles_pra_ite_isanswer = 20
            }
          })
          let ispass = item.answer.length > 0
          let isvalpass = item.question_item.some(function (item2) {
            return item2.teles_pra_ite_title == ""
          })
          if (pass && !ispass) {
            error = "请勾选第" + (index + 1) + "题正确答案"
            pass = false
          }
          if (pass && isvalpass) {
            error = "请填写第" + (index + 1) + "题正确答案"
            pass = false
          }
        }
        if (item.syque_typ_id == 5 || item.syque_typ_id == 2) {
          let ispass = item.question_item.some(function (item2) {
            return item2.teles_pra_ite_isanswer == 10
          })
          let isvalpass = item.question_item.some(function (item2) {

            return item2.teles_pra_ite_title == ""
          })
          if (pass && !ispass) {
            error = "请勾选第" + (index + 1) + "题正确答案"
            pass = false
          }
          if (pass && isvalpass) {
            error = "请填写第" + (index + 1) + "题正确答案"
            pass = false
          }
        }
        if (item.syque_typ_id == 3 || item.syque_typ_id == 4 || item.syque_typ_id == 7 || item.syque_typ_id == 8) {
          let teles_pra_ite_title = []
          item.question_item.map((item2, index2) => {
            //填空题设置默认值
            if (item2.teles_pra_ite_title.length > 0) {
              teles_pra_ite_title.push(item2.teles_pra_ite_title)
            } else {
              error = "请填写第" + (index + 1) + "题正确答案"
              pass = false
            }
          })
          item.question_item = [{ teles_pra_ite_code: "", teles_pra_ite_isanswer: "10", teles_pra_ite_title: teles_pra_ite_title.toString() }]
        }
      })
      if (!pass) {
        this.$message({
          type: "info",
          duration: 2500,
          message: error
        });
      } else {
        this.submitQuestionDocload = true
        let params = {
          tecla_id: this.teachData.tecla_id,
          questions: newsDoc
        };
        let res = await teach.$batchadd(params);
        if (res) {
          this.$message({
            type: "success",
            duration: 1500,
            message: "试题已加入！",
          });
          this.showQuestionDoc = false
          this.getPractise();
        }
        this.submitQuestionDocload = false
      }
    },
    // 试卷切题完成请求
    async submitCuttingProblem(newsDoc){
      let params = {
        tetea_id: this.teachData.tecla_id,
        qus_type:2,
        questions: newsDoc
      };
      this.loadingall=true
      let res = await $ocradd(params);
      if(res){
        this.$message({
            type: "success",
            duration: 1500,
            message: "试题已加入！",
        });
        this.showCutting=false;
        this.loadingall=false;
        this.getPractise()
      }else{
        this.loadingall=false;
      }
    },
    
    /** 下载课件、知识点、课堂练习、作业 */
    async downloadLessons() {
      if (!this.teachData.sccou_cha_title){
        return this.$message.warning("请设置此堂课程内容");
      }
      if(this.downloadLessonskoading){
        return false;
      }
      this.downloadLessonskoading=true
      let searchTitle = this.teachData.tecla_sccla_name + '--' + this.teachData.sccou_cha_titles_name;
      const params = {
        searchTitle:searchTitle,
        courseware: this.courseware,
        teachKnowledge:this.teachKnowledge,
        questions: this.teachPractise,
        teachHomework: this.teachHomework,
        prepareSchoolHomework: this.prepareSchoolHomework
      };
      
      //console.log(params);return;
      let { data } = await teach.$downloadLessons(params);
      if(data){
        if(data.status){
          //window.open(data.data.full_file_url, '_blank');
          this.startChecking(data.data.pdf_files_key);
        }else{
          this.$message({
            type: "error",
              duration: 2500,
              message: data.msg,
          });
        }
        
      }
    },
    async startChecking(pdf_files_key) {
      setTimeout(() => {
        intervalId = setInterval(() => {
          this.checkPdfFilesKey(pdf_files_key);
        }, 3000);
      }, 5000);
    },
    async checkPdfFilesKey(key){
      let { data } = await teach.$pdfIsCreate(key);
      if(data){
        if(data.status){
          window.open(data.data.pdf_files_val, '_blank');
          clearInterval(intervalId);
          this.downloadLessonskoading=false
        }
      }
    },
    htmlEncode(htmlContent) {
      if (typeof htmlContent !== 'string') {
        return ''; // 如果不是字符串，返回空字符串，避免报错
      }
      if (htmlContent.includes('<')) {
        htmlContent=htmlContent.replace(/<(?![^<>]*>)/g, "&lt;");
      }
      return htmlContent;
    },
  },
};
</script>
